export default {
	evaluate: {
		title: '评论反馈',
		compre_rate: '综合评分',
		full_marks: '满分5分',
		item: '条',
		comment: '评论',
		item_comment: '条评论',
		default_sort: '默认排序',
		desc_score: '评分从高到低',
		asc_score: '评分从低到高',
		my_publish: '我发表的',
		reply: '回复：',
		no_comment: '暂无评论',
		dialog_title: '评论',
		dialog_remark: '告诉我们您的体验',
		stars: '评分',
		valid_star: '评分不能为空',
		pl_star: '请选择评分',
		name: '名称',
		empty_name: '名称不能为空',
		valid_name: '请至少输入4个字符',
		pl_name: '请输入您的名称',
		feedback_reviews: '评价反馈',
		valid_feedback_reviews: '请输入评价反馈',
		pl_feedback_reviews: '请输入您的评价或者反馈',
		clause1: '此评论准确且符合我们的',
		clause2: '，它不具有威胁性和诽谤性，并且它不会被用来换取激励',
		terms_service: '服务条款',
		related_terms: '相关条款',
		term: '条款',
	},
}
