export default {
	common: {
		pl_search: 'Please enter search keyword',
		handle: 'Action',
		ends_at: 'Expired at',
		created_at: 'Created at',
		pay_at: 'Paid at',
		action_success: 'Action successful',
		tip: 'Tip',
		cancel: 'Cancel',
		confirm: 'OK',
		unpaid: 'Unpaid',
		paid: 'Paid',
		payment_failed: 'Payment failed',
		cancelled: 'Cancelled',
		edit_success: 'Edit successful',
		pl_start_at: 'Select start date',
		pl_ends_at: 'Select end date',
		back: 'Back',
		msg_edit:
			'The content edited this time has not been saved, are you sure you want to abandon the changes?',
		msg_edit_title: 'Abandon current changes?',
		submit_leave: 'Are you sure you want to leave the current page?',
		start_at: 'Start date',
		end_at: 'End date',
		msg_title: 'Confirm the execution of this operation?',
		warning: 'Warning',
		close: 'Close',
		copy_success: 'Copy succeeded',
		copy_failed: 'Copy failed',
		more: 'View more',
		gmt5: '(GMT-05:00) Eastern Time (US and Canada)',
		gmt8: '(GMT+08:00) Beijing Time',
		'gmt-7': '(GMT-07:00) Arizona Time',
		'gmt-8': '(GMT-08:00) Pacific Time (US and Canada)',
		'gmt-9': '(GMT-09:00) Alaska Time',
		'gmt-10': '(GMT+00:00) Edinburgh, London',
		'gmt-11': '(GMT-11:00) American Samoa',
		'gmt-12': '(GMT-12:00) International Date Line West',
		'gmt-13': '(GMT-10:00) Hawaii',
		'gmt-14': '(GMT-07:00) Arizona',
		'gmt-15': '(GMT-06:00) Central Time (US and Canada)',
		'gmt-16': '(GMT-03:00) Brasilia',
		'gmt-17': '(GMT-02:00) Mid-Atlantic',
		'gmt-18': '(GMT-01:00) Azores',
		'gmt-19': '(GMT+01:00) Amsterdam',
		'gmt-20': '(GMT+02:00) Athens',
		'gmt-21': '(GMT+03:00) Moscow, St. Petersburg',
		'gmt-22': '(GMT+04:00) Abu Dhabi, Muscat',
		'gmt-23': '(GMT+05:00) Islamabad, Karachi',
		'gmt-24': '(GMT+06:00) Astana, Dhaka',
		'gmt-25': '(GMT+07:00) Bangkok, Hanoi',
		'gmt-26': '(GMT+09:00) Tokyo, Seoul',
		'gmt-27': '(GMT+10:00) Canberra, Melbourne',
		'gmt-28': '(GMT+11:00) Magadan',
		'gmt-29': '(GMT+12:00) Auckland, Wellington',
		'gmt-30': '(GMT+13:00) Samoa',
		'gmt-31': '(GMT-04:00) Atlantic Time (Canada)',
		man_refresh: 'Refresh',
		not_upload: 'Not uploaded',
		uploaded: 'Uploaded',
		upload_fail: 'Upload failed',
	},
}
