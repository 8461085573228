export default {
	layout: {
		user_center: 'Account',
		website_cons: 'Support',
		help_center: 'Help Center',
		validity_period: 'Validity until:',
		edit_pass: 'Change password',
		logout: 'Logout',
		msg_logout: 'This operation will log out, do you want to continue?',
		quit_success: 'Exit successfully',
		logout_success: 'Logout successful',
		subaccount_privileges: 'Account privileges',
		privileges1: '1. Your account has met [VIP] or the commission rate is lower than 0.6%',
		privileges2: '2. Invite your friends to join the platform and enjoy 0 site building fees',
		privileges3: `3. Your friend's store commission/consumption will be deducted from the wallet balance of the current account`,
		privileges4:
			'4. You need to export EXCEL in your account regularly, and charge your friends (you can earn commission difference)',
		privileges5:
			'5. It is recommended to charge friends a commission ratio of 0.8 ~ 1.2% of the market price',
		privileges6:
			'6. After a friend joins the platform, submit the associated account permission to the website construction consultant/sales',
		remain: 'You need to log in again after the password is changed',
		old_pass: 'Old password',
		pl_old_pass: 'Please enter the old password',
		new_pass: 'New password',
		pl_new_pass: 'Please enter a new password',
		confirm_pass: 'Confirm new password',
		pl_confirm_pass: 'Please enter the new password again',
		edit_pass_success: 'Password changed successfully',
		change_avatar: 'Change avatar',
		choose_avatar: 'Choose an avatar that suits you',
		change_lang: 'Language',
		chinese: 'Chinese',
		pass_len: 'Password length must be between 7 and 18 characters.',
		pass_not: 'The two passwords entered do not match.',
		wxamp: 'Small program',
		wxamp_qr: 'Wechat scan code open small program',
		personal: {
			title: 'Personal center',
			lang: 'Admin language',
			zh: 'Chinese',
			en: 'English',
			desc: 'The store backend currently supports Simplified Chinese and English. You can switch to your preferred language for use.',
			cur_user: 'Current account: ',
			token_desc1: 'Access',
			token_desc2: `, using Token to access orders and customer data under the current member, and enabling the shipment feature.`,
			continue: 'Continue',
			dialog_desc:
				'After refreshing, the previous Token will automatically become invalid. You need to use the new Token for authentication. Are you sure you want to refresh the Token?',
			openApi: 'Mix-API',
			twofa: 'Two-Factor Authentication',
			no_validation: 'No Validation',
			phone_code: 'SMS Verification Code',
			phone_code_msg: 'After binding, it can be verified twice by SMS verification code at login',
			pass_reminder: 'Password Reminder',
			go_modification: 'Go to Modification',
			go_modification_1:
				'1. The current password password is too simple, we suggest you change it. Changes will no longer be prompted.',
			go_modification_2:
				'2. Enable Two-Factor Authentication (2FA). 2FA is an additional layer of security. When logging in, in addition to your password, you will need to provide a security key for secondary verification.',
			mfa: 'Virtual MFA',
			mfa_tips:
				'Virtual MFA is a simple and effective two-factor security authentication method. It can bind applications or hardware (such as smartphones) capable of generating digital authentication, adding an extra layer of security protection beyond usernames and passwords.',
			unbind: 'Unbind',
			set_success: 'Set Successful',
			bind_mfa: 'Bind MFA',
			mfa_steps1: 'Verify Identity',
			mfa_code: 'Verification Code',
			down_mfa: 'Download MFA',
			step1: 'Step 1:',
			step1_tips: `For iPhone, search for "Microsoft Authenticator" in the App Store. For Android, search for "Microsoft Authenticator" in the application marketplace.`,
			step2: 'Step 2:',
			step2_tips: `Open the Microsoft Authenticator application and click on "Add new account" (or the "+" sign for iOS) and select "Scan barcode." Alternatively, you can choose to add the account using the provided key.`,
			step3: 'Step 3:',
			step3_tips:
				'Once configured, your phone will display a 6-digit number that changes every 30 seconds. This number is your two-factor authentication code. Please do not delete this account from your two-factor authentication, as it will prevent you from performing account operations.',
			down_next: 'After downloading',
			down_next_tips:
				'Scan the following QR code using Microsoft Authenticator to obtain a 6-digit verification code.',
			mfa_codes: 'MFA Codes',
			code6: 'Enter 6-digit verification code',
			bind_success: 'Binding Complete',
			qr_tips:
				'If you are unable to bind successfully or experience multiple verification errors, please try rescanning the QR code using your phone.',
			app: 'Prohibit employee access',
			store_change: 'Switch store in store backend',
			store_change_radio1: 'All invisible',
			store_change_radio2: 'All visible',
			store_change_radio3: 'Only visible to administrators',
			set_white: 'IP whitelist',
			white: 'Whitelist',
			white_place:
				'Please enter an IP or IP segment (such as 192.1.1.* or 192.1.*.*, 0-255 is allowed respectively), separate multiple IP addresses with carriage returns',
			white_ip_err: 'The following IP format is incorrect:',
			white_tip1:
				'In order to increase system security and effectively control access rights, please set an IP whitelist to continue accessing MixAPI:',
			white_tip2: '1. Allow authorized IP addresses to access the system;',
			white_tip3:
				'2. Prohibit unauthorized IP addresses from accessing to reduce data leakage and attack risks;',
			white_tip4: '3. Please use it reasonably and prohibit abuse or frequent requests. ',
			del_tip: 'Delete prompt',
			del_tip_sub1: 'Are you sure to delete the token',
			del_tip_sub2: '1. You will no longer be able to access it after deletion;',
			del_tip_sub3: '2. If you need to re-enable it, please click the "Refresh" button',
		},
		singout: {
			user: 'Sign out account',
			tips: 'If you want to permanently disable this account, please click "Sign Out Account" to proceed.',
			model_t: 'Apply for account cancellation',
			last: 'Previous',
			next: 'Next',
			setup_1: 'Cancellation notice',
			setup_2: 'Identity verification',
			setup_tip1:
				'You need to understand the following information and confirm whether you want to cancel the account.',
			setup_tip2: 'After account cancellation,',
			setup_tip3:
				'you will no longer be able to log in or access related information using this account,',
			setup_tip4: 'including store listings, billing management, wallet balance, etc.',
			setup_tip5:
				'To ensure operational continuity, canceling the account will not automatically cancel any associated stores. If you need to cancel a store, please log in to the corresponding store backend and proceed with the "Store Cancellation" process.',
			setup_tip6:
				'Account cancellation is irreversible. Please make sure to back up any relevant information before proceeding with the cancellation.',
			setup_tip7:
				'Deactivating the main account will result in the simultaneous deactivation of all associated sub-accounts. Please ensure that you have thoroughly considered the subsequent handling of relevant business matters.',
			mobile: 'User account currently requesting cancellation',
			password: 'Login password for the current user account',
			rule_password: 'Please enter the login password',
			pl_password: 'Please enter the login password for the user center',
			code: 'Get verification code',
			rule_code: 'Please enter the verification code',
			send: 'Send',
			suscess: 'Account successfully cancelled. Thank you for using our services!',
			logins: 's, the page will automatically redirect',
			confirm_out: 'Confirm cancellation',
			ok: 'Finish',
		},
		hw_password_v:
			'The password does not meet the requirements and should contain at least one capital letter, special characters, and numbers',
		hw_password_v2: 'The password cannot be the same as the account',
		v_pasword_k2: 'The two passwords entered do not match.',
		add_info: 'Additional information',
		add_info_tip:
			'To ensure you can conveniently access the user center and fully enjoy the after-sales service of our products, please supplement your phone number and password information when logging in for the first time. Thank you for your support and cooperation!',
		add_msg:
			"Operation successful! Please use your 'phone number' and 'password' to log in to the user center again.",
	},
}
