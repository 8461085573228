export default {
	domains: {
		title: 'Domains',
		reg_domain: 'Register a domain',
		pl_domain: 'Please enter a domain name',
		dashboard: 'Control Panel',
		dns: 'Domain name resolution',
		not_started: 'Not started',
		effective: 'Effective',
		expired: 'Expired',
		domain: 'Domain name',
		status: 'Status',
		reg_at: 'Registered at',
		add_dns: 'Add resolution',
		paused_dns: 'Pause/enable domain name resolution',
		back: 'Back to domains',
		host_name: 'Host name',
		type: 'Type',
		mx: 'MX priority',
		value: 'Parsed value',
		close: 'Close',
		open: 'Open',
		msg_dns: 'Does the domain name resolve?',
		confirm: 'OK',
		msg_del: 'Are you sure to delete this piece of data?',
		domain_info: 'Domain name information',
		reg_at_field: 'Registered at:',
		ends_at_field: 'Expired at:',
		cert_field: 'Certificate:',
		business_field: 'Domain name registrar:',
		dns_service: 'DNS server',
		dns_service_field: 'DNS server:',
		main_domain: 'Main domain name server name:',
		subdomain: 'Secondary domain name server name:',
		all_user_info: `Everyone's information`,
		last_name_field: 'Last Name',
		pl_last_name_field: 'Please enter the last name',
		name_field: 'Name',
		pl_name_field: 'Please enter a name',
		phone_field: 'Phone',
		pl_phone_field: 'Please enter a mobile phone number',
		email_field: 'Email',
		pl_email_field: 'Please enter your email address',
		valid_email_field: 'The email address is empty or the email address is invalid',
		company_field: 'Company',
		pl_company_field: 'Please enter the company',
		country_field: 'Country',
		pl_country_field: 'Please select a country',
		province_field: 'Province',
		pl_province_field: 'Please enter the province',
		city_field: 'City',
		pl_city_field: 'Please enter the city',
		address_field: 'Address',
		pl_address_field: 'Please enter address',
		zip_field: 'Zip code',
		pl_zip_field: 'Please enter the zip code',
		fax_field: 'Fax',
		pl_fax_field: 'Please enter the fax',
		renewal: 'Renewal',
		look_cert: 'View certificate',
		edit_dns_service: 'DNSPOD server',
		default_dns_service: 'Default DNS server',
		custom_service: 'Custom server',
		edit_info: 'Modify information',
		query_results: 'Query results',
		tips1:
			'1. Check the domain name suffix you need to query (multiple choices are allowed), and the default system will recommend several commonly used domain name suffixes for you',
		tips2: '2. Enter your desired domain name',
		tips3: '3. Click Query and select the available domain name you want',
		tips4: '4. Complete registration information and purchase',
		re_query: 'Requery',
		pl_domain_search: `Domain search, allowing only letters, numbers, and ' - '`,
		pl_domain_search1: `Domain search, allowing only letters, numbers, and ' - '`,
		select_all: 'Select all',
		first_price: 'First year price',
		renewal_price: 'Renewal price',
		year: 'Year',
		msg_search: 'Please enter a valid domain name prefix (you can enter numbers, letters and -)',
		all_owner: 'Owner information',
		admin_info: 'Administrator information',
		tech_info: 'Technical Information',
		bill_info: 'Financial information',
		complete_info: 'Complete information',
		cur_domain: 'Currently selected domain name',
		confirm_order: 'Confirm order',
		order_content_item: 'Order content:',
		buy_domain_item: 'Buy domain name:',
		buy_amount_item: 'Buy amount:',
		pay_method_item: 'Payment method:',
		ends_at_item: 'Expired at:',
		pay_amount_item: 'Payment amount:',
		domain_buy: 'Domain name purchase',
		wallet_balance: 'Balance',
		reg_success: 'Registration successful',
		valid_name: 'Only enter numbers, letters, underscores, and dashes',
		valid_fix: 'Only enter numbers and horizontal lines',
		valid_num: 'Only enter numbers',
		by: 'By',
		reg_desc: 'Registered and recorded in the international top-level domain name database. ',
		domainer: 'Domain name holder',
		domain_reg_at: 'Domain name registration date',
		domain_ends_at: 'Domain Expiration Date',
		radmin1:
			'The following instructions and the main text of this certificate constitute a unified whole of this certificate and are inseparable',
		radmin2:
			'1. The organization or individual listed on this certificate is the legal holder of the listed domain name. The domain name holder enjoys all rights under the domain name according to law. ',
		radmin3: `2. This certificate does not indicate that the registration authority of the domain name has made any express or implied judgment, confirmation, guarantee, or other Any form of expression. The registrar to which the domain name belongs also has no responsibility or obligation to make the above judgments, confirmations, guarantees, or any other form of expression of intention. `,
		radmin4: `3. Any disputes or conflicts with any third party that may be caused by the registration or use of the domain names listed in this certificate shall be borne by the domain name holder himself, and the registration authority to which the domain name belongs shall not assume any legal responsibility. The registry of the domain name does not
		Act as a witness, mediator or other form of participant in such disputes or conflicts. `,
		radmin5: `4. This certificate shall not be used for illegal purposes, and the registration authority to which the domain name belongs shall not bear any legal responsibility arising or possibly arising therefrom. `,
		radmin6: `5. This certificate is only used to prove the status of the domain name and the holder at the time the domain name certificate was printed. If the domain name is transferred to another person, the domain name is transferred out of the registration authority of the domain name, the domain name is deleted, or other changes occur to the status of the domain name and the information of the holder, this certificate will no longer have the effect of proof. When this certificate is applied for, issued, displayed or used in any other form,
		It means that the holder or contact person of this certificate has read, understood and agreed to the provisions of the above terms. `,
		cert_print_at: 'Certificate print date',
		edit_domain: 'Modify domain name resolution',
		pl_host_name: 'Please enter the host name',
		pl_enter: 'Please enter',
		pl_type: 'Please select a type',
		pl_select: 'Please select',
		parsed_val: 'parsed value',
		pl_parsed_val: 'Please enter the parsed value',
		ten_min: 'Ten minutes',
		half_hour: 'Half an hour',
		one_hour: 'One hour',
		half_day: 'Half day',
		one_day: 'One day',
		one_week: 'One week',
		buy: 'Buy',
		unreg: 'Unregistered',
		not_reg: 'Cannot be registered',
		query_failed: 'Query failed',
		main_service_name: 'Main domain name server name',
		pl_main_service_name: 'Please enter the name of the main domain name server',
		submain_service_name: 'Secondary domain name server name',
		pl_submain_service_name: 'Please enter the secondary domain name server name',
		renewal_domain: 'Renewal domain name:',
		renewal_amount: 'Renewal fee:',
		confirm_pay: 'Confirm payment',
		renewal_success: 'Renewal successful',
		fax: 'Fax',
		pl_fax: 'Please enter a fax',
		cur_checked_domain: 'Currently checked domain name:',
		use_amount_reg: 'Use the last registration information',
		buy_created_order: 'Purchase order created successfully',
		renew_created_order: 'Renewal order created successfully',
		valid_fax_len: 'Only enter 4~20 digits',
		reabte: '* Current account enjoys KA customer discount',
		no_reabte:
			'* If you have a large number of domain registrations, please contact us to activate KA customer discount',
		first_year: 'First Year',
		renew_year: 'Renewal/Year',
		rebate_text: 'Now',
		search_text: 'Search',
		batch_buy: 'Bulk Purchase',
		valid_spec: 'Please enter 9 to 100 characters, special characters are not allowed.',
		valid_min_four: 'At least 4 characters required',
		valid_not_number: 'Only letters are allowed.',
		valid_str_min: 'At least 2 characters required',
		logout_domain: 'Logout from domain',
		logout_domain_msg: 'Confirm logout from the current domain?',
		batch_desc:
			'For your convenience in making a purchase, in addition to the unchangeable information you need to provide below, we will also generate random profile information for each domain. After your purchase is complete, you can modify other profile information at any time.',
		batch_sign: 'Batch Registration',
		demand: 'Query',
		demand_msg: `Please enter complete domain names (including prefixes and suffixes). For multiple domains, please input each on a new line. For example:\ntest.com\ncntest.top\nentest.shop`,
		no_domains: 'No domains found',
		domain_batch_buy: 'Bulk domain purchase',
		total: '',
		piece: ' in total',
		dns_com_service: 'DNSCOM server',
	},
	westForm: {
		field1: 'Owner type',
		field_val1: 'Individual',
		field_val2: 'Company',
		filed2: 'Owner company name',
		filed2_msg: 'Please enter the owner company name',
		field3: 'Contact full name',
		field3_msg: 'Please enter the contact full name',
		field4: 'Last name',
		field4_msg: 'Please enter the last name',
		field5: 'First name',
		field5_msg: 'Please enter the first name',
		field6: 'Country',
		field6_msg: 'Please select a country',
		field7: 'Area code',
		field7_msg: 'Please select an area code',
		field8: 'Province',
		field8_msg: 'Please enter the province',
		field9: 'City',
		field9_msg: 'Please enter the city',
		field10: 'County',
		field10_msg: 'Please enter the county',
		field11: 'Mailing address',
		field11_msg: 'Please enter the mailing address',
		field12: 'Postal code',
		field12_msg: 'Please enter the postal code',
		field13: 'Contact phone type',
		field13_val1: 'Mobile',
		field13_val2: 'Landline',
		field14: 'Mobile number',
		field14_msg: 'Please enter the mobile number',
		field15: 'Landline area code',
		field15_msg: 'Please enter the landline area code',
		field16: 'Landline number',
		field16_msg: 'Please enter the landline number',
		field17: 'Landline extension',
		field17_msg: 'Please enter the landline extension',
		field18: 'Email',
		field18_msg: 'Please enter the email',
		field18_valid: 'Please enter a valid email address',
		field19: 'Company name (English)',
		field19_msg: 'Please enter the company name (English)',
		field20: 'Last name (English)',
		field20_msg: 'Please enter the last name (English)',
		field21: 'First name (English)',
		field21_msg: 'Please enter the first name (English)',
		field22: 'Province (English)',
		field22_msg: 'Please enter the province (English)',
		field23: 'City (English)',
		field23_msg: 'Please enter the city (English)',
		field24: 'Mailing address (English)',
		field24_msg: 'Please enter the mailing address (English)',
		field25: 'HK domain document type',
		field25_msg: 'Please select the HK domain document type',
		field26: 'HK domain document Number',
		field26_msg: 'Please enter the HK domain document number',
		field27: 'Domain document type',
		field27_msg: 'Please select the domain document type',
		field28: 'Domain document value',
		field28_msg: 'Please enter the domain document value',
		field29: 'Call identifier',
		field29_msg: 'Please enter the call identifier',
		field_valid_len: 'Minimum {len} characters required',
		hktype1: 'ORGanization Code Certificate',
		hktype2: 'BUSiness License',
		hktype3: 'HONg Kong Company Registration Certificate',
		hktype4: 'MAInland China ID Card',
		hktype5: 'HONg Kong ID Card',
		hktype6: 'PAssport Number',
		hktype7: 'BOrth Certificate',
		gswltype1: 'ORGanization Code Certificate',
		gswltype2: 'BUSiness License (Enter Unified Social Credit Code)',
		gswltype3: 'UNified Social Credit Code Certificate',
		gswltype4: 'MIlitary Unit Code',
		gswltype5: 'MIlitary Unit Paid Service License',
		gswltype6: 'INstitutional Legal Person Certificate',
		gswltype7: 'RRegistration Certificate of Foreign Enterprise Permanent Representative Office',
		gswltype8: 'SSocial Organization Legal Person Registration Certificate',
		gswltype9: 'RReligious Activity Venue Registration Certificate',
		gswltype10: 'NNon-Enterprise Unit Registration Certificate',
		gswltype11: 'FFoundation Legal Person Registration Certificate',
		gswltype12: 'LLaw Firm Practice License',
		gswltype13: 'RRegistration Certificate of Foreign Cultural Center in China',
		gswltype14:
			'RRegistration Certificate of Foreign Government Tourism Department Permanent Representative Office',
		gswltype15: 'JJudicial Appraisal License',
		gswltype16: 'SSocial Service Organization Registration Certificate',
		gswltype17: 'PPrivate School Operating Permit',
		gswltype18: 'MMedical Institution Operating License',
		gswltype19: 'OOverseas Organization Document',
		gswltype20: 'NNotary Public Organization Practice License',
		gswltype21: 'BBeijing Foreign Embassy Personnel Children School Operating Permit',
		gswltype22:
			'OOther Documents with Unified Social Credit Code (Non-Business License, Non-Organization Code Certificate)',
		gswltype23: 'IID Card',
		gswltype24: 'PPassport',
		gswltype25: 'MMainland Travel Permit for Hong Kong and Macau Residents',
		gswltype26: 'MMainland Travel Permit for Taiwan Residents',
		gswltype27: 'PPermanent Residence ID Card for Foreigners',
		gswltype28: 'RResidence Permit for Hong Kong, Macau, and Taiwan Residents',
	},
}
