import { Module } from 'vuex'
// 此处加上 `.ts` 后缀报错，具体原因不详
import { GlobalState, RootStateTypes } from '/@/store/interface/index'

// el-aside loading
const globalModule: Module<GlobalState, RootStateTypes> = {
	namespaced: true,
	state: {
		loading: false,
		text: '',
	},
	mutations: {
		setLoading(state: GlobalState, data: boolean) {
			state.loading = data
		},
		setText(state: GlobalState, data: string) {
			state.text = data
		},
	},
}

export default globalModule
