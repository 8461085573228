export default {
	login: {
		help_center: '帮助中心',
		terms_service: '服务条款',
		login: '登录',
		reg: '注册',
		pl_phone: '请输入手机号',
		pl_password: '请输入密码',
		forget_password: '忘记密码',
		login_success: '登录成功',
		pl_code: '请输入验证码',
		not_empty: '不能为空',
		pl_pass_again: '请再次输入密码',
		confirm_edit: '确认修改',
		back_login: '返回登录',
		valid_phone: '手机号不合法',
		valid_pass_len: '密码长度为7-18',
		pl_shop_name: '请输入店铺名称',
		pl_coupon: '请输入优惠码(选填)',
		i_agree: '我已阅读并同意',
		terms_policies: '《条款与政策》',
		reg_success: '注册成功',
		get_code: '获取短信验证码',
		phone_error: '手机号不合法,请检查',
		send_code_success: '短信发送成功请留意手机信息',
		area_code: '区号',
		账号或密码错误: '账号或密码错误',
		用户不存在: '用户不存在',
		不能为空: '不能为空',
		必须是数字: '必须是数字',
		手机号码格式错误: '手机号码格式错误',
		用户已存在: '用户已存在',
		两次密码输入不一致: '两次密码输入不一致',
		手机验证码错误或失效: '手机验证码错误或失效',
		优惠码无效: '优惠码无效',
		优惠码已过期: '优惠码已过期',
		密码错误: '密码错误',
		pl_category: '请选择商品类目',
		optional: '选填',
		pl_pid: '请选择主账号',
		valid_pass_rule: '密码长度不少于7位，并且至少包含数字和字母',
		free: '7天内免登录',
		msg_sms: '短信验证',
		verification: '验证',
		user: '为确保账号',
		code: '是您本人操作，请你输入验证码',
		pl_graph_code: '请输入图形验证码',
	},
}
