export default {
	billings: {
		title: '账单管理',
		bills: '账单',
		download_bill: '下载选中账单',
		shop_name: '店铺名称',
		billing_no: '账单编号',
		billing_name: '账单名称',
		billing_detail: '账单明细',
		price: '价格',
		pay_method: '支付方式',
		stauts: '状态',
		down_success: '下载成功',
		card: '月结虚拟卡',
		all_pay: '所有支付方式',
		bill_total: '账单总金额(USD):',
		download_all: '下载全部账单',
		title_desc:
			'请以实际扣款金额为准。导出的数据仅精确到小数点后两位，可能与实际扣款金额略有差异。',
		exclude_zero_bill: '排除0元账单',
	},
}
