export default {
	incentive: {
		billrecord: {
			title: 'Billing',
			reject: 'Rejected',
			pass: 'Successed',
			pending: 'Pennding',
			settling: 'Paying',
			settled: 'Paid',
			bill_no: 'Number',
			user: 'User',
			set_at: 'Paid at',
			bill_status: 'Status',
			expected_income: 'Income',
			checkout_status: 'Checkout Status',
			all: 'All',
			pl_bill_no: 'Please enter the bill number',
		},
		cashout: {
			withdrawal: 'Withdrawals',
			apply_set: 'Apply',
			collect_manage: 'Receipt Management',
			config_account: '(Please set a receiving account first)',
			drive_set: 'Settled income of Incentive plan',
			drive_wait: 'Pending income of Incentive plan',
			drive_wait_tip: 'After promotion users generate bills ',
			drive_wait_tip1: ' days later, the income can be settled',
			in_progress: 'Application in progress',
			set_success: 'Settlement successful',
			set_err: 'Settlement failed',
			card: 'Bank card',
			alpay: 'Alipay',
			apply_no: 'Apply number',
			set_status: 'Paid status',
			income_price: 'Income amount',
			income_info: 'Payment info',
			process_at: 'Processed at',
			apply_at: 'applied at',
			remark: 'Remark',
			msg_set_account: 'The receiving account is not currently set, please set it first!',
			income_payment: 'Payment method',
			withdrawal_price: 'Withdrawal amount',
			pl_channel: 'Please enter the channel name',
			withdrawal_price_info:
				'The withdrawal amount will be converted according to the real-time exchange rate on the day of payment, and the minimum withdrawal amount is',
			bill_detail: 'Bill Details',
			bill_no: 'Bill number',
			reg_user: 'Registered user',
			bill_type: 'Type',
			version_sub: 'Store Version',
			store_commission: 'Store commission',
			bill_at: 'Created at',
			bill_price: 'Amount',
			profit: 'Profit',
			all_status: 'All',
			pl_cert_no: 'Please enter the application serial number',
		},
		overview: {
			dashboard: 'Overview',
			add_channel: 'Add channel',
			incentive_post: 'Affiliate announcement',
			all_channel: 'All channels',
			share_link: 'Channel share link',
			channel_link: 'Channel link',
			today: 'Today',
			yesterday: 'Yesterday',
			last_7: 'Last 7 days',
			last_15: 'Last 15 days',
			last_30: 'Last 30 days',
			channel_name: 'Channel name',
			channel_name_tip:
				'You can create multiple different channels, and the link parameters of each channel will be different, so that you can compare the promotion of different channels',
			reg_count: 'Registered users',
			income: 'Income',
			pl_channel: 'Please enter the channel name',
			reg_user_field: 'Registered user:',
			total_price_field: 'Total income:',
			collection_manage: 'Receipt management',
			collection_pay: 'Payment method',
			collection_pay_tip:
				'When you apply for cash withdrawal, we will transfer money to the configured receiving account, please ensure that the receiving payment account information is correct',
			bank: 'Cccount bank',
			pl_bank: 'Please enter the account bank',
			account: 'Receipt account',
			pl_account: 'Please enter the receiving account number',
			account_name: 'Receipt name',
			pl_account_name: 'Please enter the receiving name',
			time: 'Time',
		},
	},
}
