export default {
	incentive: {
		billrecord: {
			title: '账单记录',
			reject: '拒绝',
			pass: '通过',
			pending: '待结算',
			settling: '结算中',
			settled: '已结算',
			bill_no: '账单编号',
			user: '用户',
			set_at: '结算时间',
			bill_status: '账单状态',
			expected_income: '预计收入',
			checkout_status: '结账状态',
			all: '全部',
			pl_bill_no: '请输入账单编号',
		},
		cashout: {
			withdrawal: '提现管理',
			apply_set: '申请结算',
			collect_manage: '收款管理',
			config_account: '(请先配置收款账号)',
			drive_set: '激励计划可结算收益',
			drive_wait: '激励计划待结算收入',
			drive_wait_tip: '推广用户产生账单费用后的',
			drive_wait_tip1: '天后，才可将收益进行结算',
			in_progress: '申请中',
			set_success: '结算成功',
			set_err: '结算失败',
			card: '银行卡',
			alpay: '支付宝',
			apply_no: '申请编号',
			set_status: '结算状态',
			income_price: '收入金额',
			income_info: '收款信息',
			process_at: '处理时间',
			apply_at: '申请时间',
			remark: '备注信息',
			msg_set_account: '当前未设置收款账号请先设置!',
			income_payment: '收款方式',
			withdrawal_price: '提现金额',
			pl_channel: '请输入渠道名称',
			withdrawal_price_info: '提现金额会根据打款当天的实时汇率进行换算，最低提现金额为',
			bill_detail: '账单明细',
			bill_no: '账单编号',
			reg_user: '注册用户',
			bill_type: '账单类型',
			version_sub: '版本订阅',
			store_commission: '店铺佣金',
			bill_at: '账单时间',
			bill_price: '账单金额',
			profit: '收益',
			all_status: '全部状态',
			pl_cert_no: '请输入申请流水号',
		},
		overview: {
			dashboard: '概览',
			add_channel: '添加渠道',
			incentive_post: '激励公告',
			all_channel: '全部渠道',
			share_link: '渠道分享链接',
			channel_link: '渠道链接',
			today: '今天',
			yesterday: '昨天',
			last_7: '过去7天',
			last_15: '过去15天',
			last_30: '过去30天',
			channel_name: '渠道名称',
			channel_name_tip:
				'您可以创建多个不同渠道，每个渠道链接参数会有差异，方便您对比不同渠道的推广情况',
			reg_count: '注册用户数',
			income: '收入',
			pl_channel: '请输入渠道名称',
			reg_user_field: '注册用户：',
			total_price_field: '总收入：',
			collection_manage: '收款管理',
			collection_pay: '收款方式',
			collection_pay_tip: '在您申请提现时我们会向配置的收款账号汇款，请确保收款支付账号信息正确',
			bank: '开户行',
			pl_bank: '请输入开户行',
			account: '收款账号',
			pl_account: '请输入收款账号',
			account_name: '收款姓名',
			pl_account_name: '请输入收款姓名',
			time: '时间',
		},
	},
}
