export default {
	domains: {
		title: '域名管理',
		reg_domain: '注册域名',
		pl_domain: '请输入域名',
		dashboard: '控制面板',
		dns: '域名解析',
		not_started: '未开始',
		effective: '生效中',
		expired: '已到期',
		domain: '域名',
		status: '状态',
		reg_at: '注册时间',
		add_dns: '添加解析',
		paused_dns: '暂停/启用域名解析',
		back: '返回域名管理',
		host_name: '主机名',
		type: '类型',
		mx: 'MX优先级',
		value: '解析值',
		close: '关闭',
		open: '开启',
		msg_dns: '域名解析吗?',
		confirm: '确认',
		msg_del: '确认删除此条数据吗?',
		domain_info: '域名信息',
		reg_at_field: '注册时间：',
		ends_at_field: '到期时间：',
		cert_field: '证书：',
		business_field: '域名注册商：',
		dns_service: 'DNS服务器',
		dns_service_field: 'DNS服务器：',
		main_domain: '主域名服务器名字：',
		subdomain: '辅域名服务器名字：',
		all_user_info: '所有人信息',
		last_name_field: '姓',
		pl_last_name_field: '请输入姓',
		name_field: '名',
		pl_name_field: '请输入名',
		phone_field: '手机号码',
		pl_phone_field: '请输入手机号码',
		email_field: '电子邮箱',
		pl_email_field: '请输入电子邮箱',
		valid_email_field: '电子邮箱为空或电子邮箱不合法',
		company_field: '公司',
		pl_company_field: '请输入公司',
		country_field: '国家',
		pl_country_field: '请选择国家',
		province_field: '省份',
		pl_province_field: '请输入省份',
		city_field: '城市',
		pl_city_field: '请输入城市',
		address_field: '地址',
		pl_address_field: '请输入地址',
		zip_field: '邮编',
		pl_zip_field: '请输入邮编',
		fax_field: '传真',
		pl_fax_field: '请输入传真',
		renewal: '续费',
		look_cert: '查看证书',
		edit_dns_service: '修改DNS服务器',
		default_dns_service: 'DNSPOD服务器',
		custom_service: '自定义服务器',
		edit_info: '修改信息',
		query_results: '查询结果',
		tips1: '1.勾选你需要查询的域名后缀(可多选)、默认系统会给您推荐几个常用的域名后缀',
		tips2: '2.输入您自己想要的域名',
		tips3: '3.点击查询并选择您想要的可用域名',
		tips4: '4.完善注册信息并购买',
		re_query: '重新查询',
		pl_domain_search: '输入域名查询允许输入的格式为字母、数字、-',
		pl_domain_search1: '请输入搜索关键字，最多输入1000个每行一个关键词，如: domain、example',
		select_all: '全选',
		first_price: '首年价格',
		renewal_price: '续费价格',
		year: '年',
		msg_search: '请输入有效的域名前缀（可输入数字、字母及-）',
		all_owner: '所有人信息',
		admin_info: '管理员信息',
		tech_info: '技术信息',
		bill_info: '财务信息',
		complete_info: '完善信息',
		cur_domain: '当前选中域名',
		confirm_order: '确认订单',
		order_content_item: '订单内容：',
		buy_domain_item: '购买域名：',
		buy_amount_item: '购买费用：',
		pay_method_item: '支付方式：',
		ends_at_item: '到期时间：',
		pay_amount_item: '支付金额：',
		domain_buy: '域名购买',
		wallet_balance: '钱包余额',
		reg_success: '注册成功',
		valid_name: '只能输入数字、字母、下划线、中横线',
		valid_fix: '只能输入数字、中横线',
		valid_num: '只能输入数字',
		by: '已由',
		reg_desc: '注册，并已在国际顶级域名数据库中记录。',
		domainer: '域名持有者',
		domain_reg_at: '域名注册日期',
		domain_ends_at: '域名到期日期',
		radmin1: '以下说明与本证书主文一起构成本证书统一整体，不可分割',
		radmin2:
			'1.本证书上列出的组织或者个人是所列域名的合法持有人。该域名持有人依法享有该域名项下之各项权利。',
		radmin3: `2.本证书并不表明域名所属注册机构对本证书所列域名是否贬斥、侵害或毁损任何第三人之合法权利或利益作出任何明示或默示之评判、确认、担保，或作出其它任何形式之意思表示。域名所属注册机构亦无任何责任或义务作出上述之评判、确认、担保，或作出其它任何形式之意思表示。`,
		radmin4: `3.本证书中所列域名之注册或使用而可能引发的与任何第三人之纠纷或冲突,均由该域名持有人本人承担，域名所属注册机构不承担任何法律责任。域名所属注册机构亦不
		在此类纠纷或冲突中充当证人、调停人或其它形式之参与人。`,
		radmin5: `4. 本证书不得用于非法目的，域名所属注册机构不承担任何由此而发生或可能发生之法律责任。`,
		radmin6: `5.本证书仅用于证明本域名证书打印当时的域名状态和持有人情况。若域名被转让给其他人、域名转出域名所属注册机构、域名被删除或发生其他改变域名状态及持有人信息的情况，则本证书不再具有证明效力。当本证书被申请、出具、展示或以其它任何形式使用时，
		即表明本证书之持有人或接触人已审读、理解并同意以上各条款之规定。`,
		cert_print_at: '证书打印日期',
		edit_domain: '修改域名解析',
		pl_host_name: '请输入主机名',
		pl_enter: '请输入',
		pl_type: '请选择类型',
		pl_select: '请选择',
		parsed_val: '解析值',
		pl_parsed_val: '请输入解析值',
		ten_min: '十分钟',
		half_hour: '半小时',
		one_hour: '一小时',
		half_day: '半天',
		one_day: '一天',
		one_week: '一周',
		buy: '购买',
		unreg: '未注册',
		not_reg: '不可注册',
		query_failed: '查询失败',
		main_service_name: '主域名服务器名字',
		pl_main_service_name: '请输入主域名服务器名字',
		submain_service_name: '辅域名服务器名字',
		pl_submain_service_name: '请输入辅域名服务器名字',
		renewal_domain: '续费域名：',
		renewal_amount: '续费费用：',
		confirm_pay: '确认支付',
		renewal_success: '续费成功',
		fax: '传真',
		pl_fax: '请输入传真',
		cur_checked_domain: '当前选中域名:',
		use_amount_reg: '使用上一次注册信息',
		buy_created_order: '购买订单创建成功',
		renew_created_order: '续费订单创建成功',
		valid_fax_len: '只能输入4~20位',
		reabte: '* 当前账户享受KA客户折扣优惠',
		no_reabte: '* 若您有大量域名注册需要请联系开通KA客户折扣',
		first_year: '首年',
		renew_year: '续费/年',
		rebate_text: '优惠后',
		search_text: '搜索',
		batch_buy: '批量购买',
		valid_spec: '请输入9~100个字符，不能输入特殊字符',
		valid_min_four: '至少输入4位',
		valid_not_number: '只能输入字母',
		valid_str_min: '最少输入2个字符',
		logout_domain: '域名注销',
		logout_domain_msg: '确认要注销当前域名吗?',
		batch_desc:
			'为了方便您的购买，除了需要您填写以下不可更改信息外，我们还会为每个域名生成随机的资料信息，您购买完成后，可以随时修改其他资料信息',
		batch_sign: '批量注册',
		demand: '查询',
		demand_msg: `请输入完整域名（包括前缀和后缀，多个域名请换行输入)，例如\ntest.com\ncntest.top\nentest.shop`,
		no_domains: '未查询到域名',
		domain_batch_buy: '域名批量购买',
		total: '共',
		piece: '个',
		dns_com_service: 'DNSCOM服务器',
	},
	westForm: {
		field1: '所有者类型',
		field_val1: '个人',
		field_val2: '企业',
		filed2: '所有者单位名称',
		filed2_msg: '请输入所有者单位名称',
		field3: '联系人全称',
		field3_msg: '请输入联系人全称',
		field4: '姓',
		field4_msg: '请输入姓',
		field5: '名',
		field5_msg: '请输入名',
		field6: '国家',
		field6_msg: '请选择国家',
		field7: '区号',
		field7_msg: '请选择区号',
		field8: '省',
		field8_msg: '请输入省',
		field9: '市',
		field9_msg: '请输入市',
		field10: '县',
		field10_msg: '请输入县',
		field11: '通讯地址',
		field11_msg: '请输入通讯地址',
		field12: '邮编',
		field12_msg: '请输入邮编',
		field13: '联系电话类型',
		field13_val1: '手机',
		field13_val2: '座机',
		field14: '手机号码',
		field14_msg: '请输入手机号码',
		field15: '座机号码区号',
		field15_msg: '请输入座机号码区号',
		field16: '座机号码',
		field16_msg: '请输入座机号码',
		field17: '座机号码分机号',
		field17_msg: '请输入座机号码分机号',
		field18: '邮箱',
		field18_msg: '请输入邮箱',
		field18_valid: '请输入正确的邮箱格式',
		field19: '单位名称(英文)',
		field19_msg: '请输入单位名称(英文)',
		field20: '姓(英文)',
		field20_msg: '请输入姓(英文)',
		field21: '名(英文)',
		field21_msg: '请输入名(英文)',
		field22: '省(英文)',
		field22_msg: '请输入省(英文)',
		field23: '市(英文)',
		field23_msg: '请输入市(英文)',
		field24: '通讯地址(英文)',
		field24_msg: '请输入通讯地址(英文)',
		field25: 'hk域名证件类型',
		field25_msg: '请选择hk域名证件类型',
		field26: 'hk域名证件号码',
		field26_msg: '请输入hk域名证件号码',
		field27: '域名证件类型',
		field27_msg: '请选择域名证件类型',
		field28: '域名证件值',
		field28_msg: '请输入域名证件值',
		field29: '调用标识',
		field29_msg: '请输入调用标识',
		field_valid_len: '最少输入{len}个字符',
		hktype1: '组织机构代码证',
		hktype2: '营业执照',
		hktype3: '香港公司登记证',
		hktype4: '大陆身份证',
		hktype5: '香港身份证',
		hktype6: '护照号码',
		hktype7: '出生证明',
		gswltype1: '组织机构代码证',
		gswltype2: '工商营业执照（填统一社会信用代码）',
		gswltype3: '统一社会信用代码证书',
		gswltype4: '部队代号',
		gswltype5: '军队单位对外有偿服务许可证',
		gswltype6: '事业单位法人证书',
		gswltype7: '外国企业常驻代表机构登记证',
		gswltype8: '社会团体法人登记证书',
		gswltype9: '宗教活动场所登记证',
		gswltype10: '民办非企业单位登记证书',
		gswltype11: '基金会法人登记证书',
		gswltype12: '律师事务所执行许可证',
		gswltype13: '外国在华文化中心登记证',
		gswltype14: '外国政府旅游部门常驻代表机构批准登记证',
		gswltype15: '司法鉴定许可证',
		gswltype16: '社会服务机构登记证书',
		gswltype17: '民办学校办学许可证',
		gswltype18: '医疗机构执行许可证',
		gswltype19: '境外机构证件',
		gswltype20: '公证机构执业证',
		gswltype21: '北京市外国驻华使馆人员子女学校办学许可证',
		gswltype22: '含统一社会信用代码的其它证件(非营业执照、非组织机构代码证)',
		gswltype23: '身份证',
		gswltype24: '护照',
		gswltype25: '港澳居民来往内地通行证',
		gswltype26: '台湾居民来往大陆通行证',
		gswltype27: '外国人永久居留身份证',
		gswltype28: '港澳台居民居住证',
	},
}
