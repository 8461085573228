export default {
	evaluate: {
		title: 'Comment feedback',
		compre_rate: 'Rating',
		full_marks: 'Full score :5',
		item: '',
		comment: 'Comment',
		item_comment: ' Comments',
		default_sort: 'default',
		desc_score: 'high to low',
		asc_score: 'low to high',
		my_publish: 'My published',
		reply: 'Reply:',
		no_comment: 'No comment yet',
		dialog_title: 'Comment',
		dialog_remark: 'Tell us about your experience',
		stars: 'Rating',
		valid_star: 'Rating cannot be empty',
		pl_star: 'Please select a rating',
		name: 'Your name',
		empty_name: 'The name cannot be empty',
		valid_name: 'Please enter at least 4 characters',
		pl_name: 'Please enter your name',
		feedback_reviews: 'Your feedback',
		valid_feedback_reviews: 'Please enter your feedback',
		pl_feedback_reviews: 'Please enter your feedback',
		clause1: 'This comment is accurate and complies with our ',
		clause2:
			', it is not threatening or defamatory, and it will not be used to exchange incentives',
		terms_service: 'Terms of Service',
		related_terms: 'Related terms',
		term: 'Terms',
	},
}
