export default {
	layout: {
		user_center: '用户中心',
		website_cons: '建站顾问',
		help_center: '帮助中心',
		validity_period: '有效期至:',
		edit_pass: '修改密码',
		logout: '退出登录',
		msg_logout: '此操作将退出登录, 是否继续?',
		quit_success: '退出成功',
		logout_success: '退出登录成功',
		subaccount_privileges: '子账号特权',
		privileges1: '1. 您的账号已经满足【VIP】或者佣金比例低于0.6%',
		privileges2: '2. 邀请您的朋友加入平台，享受0建站费用',
		privileges3: '3. 您的朋友店铺佣金/消费，由当前账号的钱包余额进行抵扣',
		privileges4: '4. 您需要定期在账号导出EXCEL，向您朋友收取费用（可赚取佣金差价）',
		privileges5: '5. 建议向朋友收取市场价 0.8 ~ 1.2% 的佣金比例',
		privileges6: '6. 朋友加入平台之后，向建站顾问/销售提交关联子账号权限',
		remain: '密码修改后需要重新登录',
		old_pass: '旧密码',
		pl_old_pass: '请输入旧密码',
		new_pass: '新密码',
		pl_new_pass: '请输入新密码',
		confirm_pass: '确认新密码',
		pl_confirm_pass: '请再次输入新密码',
		edit_pass_success: '密码修改成功',
		change_avatar: '更换头像',
		choose_avatar: '选择一个适合你的头像',
		change_lang: '语言切换',
		chinese: '简体中文',
		pass_len: '密码长度必须在7~18位',
		pass_not: '两次输入的密码不一致',
		wxamp: '卖家助手',
		wxamp_qr: '微信扫码打开卖家助手',
		personal: {
			title: '个人中心',
			lang: '后台语言',
			zh: '中文',
			en: '英文',
			desc: '店铺后台当前支付简体中文，英文两种语言，您可以切换至您习惯的语言进行使用',
			cur_user: '当前账号: ',
			token_desc1: '访问',
			token_desc2: '，使用Token获取当前会员下的订单、顾客数据，并支持发货功能',
			continue: '是否继续',
			dialog_desc:
				'刷新之后，之前的Token将自动失效，您需要使用新的Token进行身份验证，确定刷新Token？',
			openApi: 'Mix-API',
			twofa: '二次验证',
			no_validation: '不验证',
			phone_code: '手机短信验证码',
			phone_code_msg: '绑定后，可以在登录时通过[短信验证码]二次校验',
			pass_reminder: '密码提醒',
			go_modification: '前往修改',
			go_modification_1: '1.当前密码口令过于简单，建议您修改。修改后将不再提醒。',
			go_modification_2: `2.启用两步验证 (2FA)。2FA 是一种额外的验证层，将增加您账号的安全性。在登录时，除了密码，您还需要提供安全密钥进行二次登录验证。`,
			mfa: '虚拟MFA',
			mfa_tips:
				'虚拟MFA是一种简单有效的双因素安全认证方式。可以绑定能够产生数字认证的应用程序或硬件（如智能手机），在用户名和密码之外额外增加一层安全保护',
			unbind: '解绑',
			set_success: '设置成功',
			bind_mfa: '绑定MFA',
			mfa_steps1: '验证身份',
			mfa_code: '验证码',
			down_mfa: '下载MFA',
			step1: '第一步：',
			step1_tips:
				'iPhone手机， 在App Store中搜索 Microsoft Authenticator；android手机，在应用市场中搜索“微软身份验证器”，或搜索 Microsoft  Authenticator。',
			step2: '第二步：',
			step2_tips:
				'在微软身份验证器Microsoft  Authenticator应用程序中，点击“添加新账户（iOS下是+号）”，然后选择“扫描条形码”。或且提供密钥方式添加。',
			step3: '第三步：',
			step3_tips:
				'配置完成后，手机上会显示一个6位数字，每隔30秒变化一次。这个数字即为您的双重验证密码。请勿删除此双重验证密码账户，否则会导致您无法进行账户操作。',
			down_next: '下载完成后',
			down_next_tips: '使用Microsoft  Authenticator（身份验证器）扫描以下二维码，获取6位验证码 ',
			mfa_codes: 'MFA验证码',
			code6: '请输入6位验证码',
			bind_success: '绑定完成',
			qr_tips: '如果您无法成功绑定或多次验证错误，请尝试使用手机重新扫描二维码',
			app: '禁止员工访问',
			store_change: '店铺后台店铺切换',
			store_change_radio1: '所有不见',
			store_change_radio2: '所有可见',
			store_change_radio3: '仅管理员见',
			set_white: 'IP白名单',
			white: '白名单',
			white_place: '请输入IP或IP段（如192.1.1.*或192.1.*.*，分别允许0-255），多个用回车隔开',
			white_ip_err: '以下ip格式错误：',
			white_tip1: '为了增加系统安全并有效控制访问权限，请设置IP白名单以继续访问MixAPI：',
			white_tip2: '1.允许经过授权的IP地址访问系统；',
			white_tip3: '2.禁止未授权IP地址访问，减少数据泄露和攻击风险；',
			white_tip4: '3.请合理使用，禁止滥用或频繁发送请求。',
			del_tip: '删除提示',
			del_tip_sub1: '是否确认删除Token',
			del_tip_sub2: '1.删除后将无法继续访问；',
			del_tip_sub3: '2.如需重新启用，请点击"刷新"按钮',
		},
		singout: {
			user: '注销账号',
			tips: '若您想要永久停用此账号，请点击【注销账号】进行操作',
			model_t: '申请注销账号',
			last: '上一步',
			next: '下一步',
			setup_1: '注销须知',
			setup_2: '身份验证',
			setup_tip1: '您需要了解以下信息，并二次确认是否需要注销账号',
			setup_tip2: '注销账号后，',
			setup_tip3: '您将无法再通过该账号登录或访问相关信息，',
			setup_tip4: '包括店铺列表、账单管理、钱包余额等',
			setup_tip5:
				'为不影响运营，注销账号并不会同时注销账号下的关联店铺。如果您需要注销店铺，请先登录对应店铺后台并操作【注销店铺】',
			setup_tip6: '账号一经注销将无法恢复，请确保在注销前已备份您需要的相关信息',
			setup_tip7: '注销主账号将同时注销关联的所有子账号。请确保您已充分考虑相关业务的后续处理',
			mobile: '当前申请注销的用户账号',
			password: '当前用户账号的登录密码',
			rule_password: '请输入登录密码',
			pl_password: '请输入用户中心的登录密码',
			code: '获取验证码',
			rule_code: '请输入验证码',
			send: '发送',
			suscess: '注销成功，感谢您的使用!',
			logins: 's后页面将自动跳转',
			confirm_out: '确认注销',
			ok: '完成',
		},
		hw_password_v: '密码不符合要求，应至少包含一个大写字母、特殊字符以及数字',
		hw_password_v2: '密码不能和账号一致',
		hw_password_v3: '密码长度最少8位',
		add_info: '补充信息',
		add_info_tip:
			'为确保您后续能够便捷地登录用户中心，并充分享有我们提供的产品售后服务，首次登录时，请您补充手机号和密码信息。感谢您的支持与配合！',
		add_msg: '操作成功！请使用"手机号"和"密码"重新登录用户中心',
	},
}
