export default {
	sonuser: {
		title: 'Accounts',
		enter_account: 'Enter account',
		edit_remark: 'Edit info',
		untie: 'Unlink the sub account',
		edit_pass: 'Change password',
		pl_pass: 'Please enter a password',
		phone: 'Mobile phone',
		store_num: 'Current store online count',
		reg_time: 'Registered at',
		remark: 'Remark',
		pl_remark: 'Please enter a comment',
		invite: 'Invite account',
		pl_phone: 'Please enter your phone number',
		pl_code: 'Please enter the verification code',
		pl_confirm_pass: 'Please confirm the password',
		pl_pass_diff: 'The two passwords entered are inconsistent',
		reg_success: 'Registered successfully',
		send_success: 'Verification code sent successfully',
		account_desc1: `Invite account: You can invite other users to register as your account, and the account's KA validity period and commission are the same as the main account. You can have up to`,
		account_desc2: 'current wallet balance',
		account_desc3: 'sub-accounts, and you will not be able to invite more if exceeded',
		account_desc4:
			'For internal team use only, reselling or borrowing is strictly prohibited. Accounts will be closed and cleared upon discovery',
		invite_person: 'Remaining invitations: ',
		user_phone: 'User phone number',
		msg_code: 'SMS verification code',
		pl_msg_code: 'Please enter SMS verification code',
		retry: 'Retry after seconds',
		get_msg_code: 'Get verification code',
		login_pass: 'Login password',
		pl_login_pass: 'Please enter login password',
		confirm_pass: 'Confirm password',
		piece: 'piece(s)',
		online_num: 'Maximum store online count',
		online_num_tip: 'Set to 0 to match the main account online store count',
		pl_online_num: 'Please enter maximum store online count',
		sonuser_tip: 'Subaccount store online count included in main account online count',
		ka_tip:
			'All stores need to be used within the KA validity period. They will not be available after expiration. To avoid any impact on your business, please contact the business team for renewal in time.',
		ka_global:
			'Your KA validity period is less than {time}. After expiration, all stores will not be available. Please contact the business team for renewal in time.',
		day: 'days',
		hours: 'hours',
		ka_expired_global:
			'Your KA validity period has expired. Please contact the business team for renewal in time.',
	},
}
