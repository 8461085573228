import jsCookie from 'js-cookie'
/**
 * window.localStorage 浏览器永久缓存
 * @method set 设置永久缓存
 * @method get 获取永久缓存
 * @method remove 移除永久缓存
 * @method clear 移除全部永久缓存
 */
export const Local = {
	// 设置永久缓存
	set(key: string, val: any) {
		if (key === 'account-font-platInfo') Session.set(key, JSON.stringify(val))

		window.localStorage.setItem(key, JSON.stringify(val))
	},
	// 获取永久缓存
	get(key: string) {
		let json: any
		if (key === 'account-font-platInfo' && Session.get(key)) json = Session.get(key)
		else json = window.localStorage.getItem(key)
		return JSON.parse(json)
	},
	// 移除永久缓存
	remove(key: string) {
		window.localStorage.removeItem(key)
	},
	// 移除全部永久缓存
	clear() {
		window.localStorage.clear()
	},
}
/**
 * window.sessionStorage 浏览器临时缓存
 * @method set 设置临时缓存
 * @method get 获取临时缓存
 * @method remove 移除临时缓存
 * @method clear 移除全部临时缓存
 */
export const Session = {
	// 设置临时缓存
	set(key: string, val: any) {
		window.sessionStorage.setItem(key, JSON.stringify(val))
	},
	// 获取临时缓存
	get(key: string) {
		const json: any = window.sessionStorage.getItem(key)
		return JSON.parse(json)
	},
	// 移除临时缓存
	remove(key: string) {
		window.sessionStorage.removeItem(key)
	},
	// 移除全部临时缓存
	clear() {
		window.sessionStorage.clear()
	},
}
/**
 * jsCookie缓存
 * @method set 设置临时缓存
 * @method get 获取临时缓存
 * @method remove 移除临时缓存
 */
export const TOKEN_NAME = 'account_font_token' //token的默认名
type Expires = {
	expires: number
	domain?: string
	path?: string
}
export const Cookies = {
	// 设置cookie缓存
	set(key: string, val: any, expire: Expires = { expires: 30 }) {
		if (!val) return
		jsCookie.set(key, val, expire)
	},
	// 获取cookie缓存
	get(key: string = TOKEN_NAME) {
		const sessionToken = Session.get(key) || undefined
		return sessionToken || jsCookie.get(key)
	},
	// 移除cookie缓存
	remove(key: string = TOKEN_NAME, expire?) {
		jsCookie.remove(key, expire)
	},
}
