export default {
	dashboard: {
		overview: '数据概览',
		login_at: '登录时间',
		hello: '您好!',
		last_at: '上次登录时间:',
		account_type: '账号类型',
		ka_account: 'KA账号',
		store_num: '店铺数',
		available: '可用',
		expiring_soon: '即将到期',
		balance: '余额',
		bills: '账单',
		pending_pay: '未支付',
		paid: '已支付',
		pay_failed: '支付失败',
		cancelled: '已作废',
		messages: '消息',
		not_read: '未读',
		readed: '已读',
		domain: '域名',
		expiring_tip: '距离到期时间小于一个月，请及时续费，避免无法正常使用',
		business: '业务大盘',
		cur_account: '当前账号',
		revenue: '营业额',
		trans_order_num: '成交订单数',
		visitor: '访客数',
		page_visitor: '页面访问量',
		customers: '顾客数',
		order_num: '订单数',
		account: '账号信息',
		online_store: '在线店铺',
		use_store: '剩余可开店铺数量',
		store_tip: '店铺订阅版本时长小于15天的店铺',
		pen: '笔',
		active_store: '活跃店铺',
		last_open: '剩余开店',
		fa2: '建议启用两步验证(2FA)，提高账号的安全性。在登录时，除了密码还需要提供安全密钥进行二次登录验证。',
		to_set: '前往设置',
		store_num_tips:
			'如果您的店铺在过去一个月内没有产生订单，可以尝试关闭这些长期无订单的店铺释放开店名额',
	},
}
