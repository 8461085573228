export default {
	orders: {
		my_order: 'My order',
		pl_order_no: 'Please enter the order number',
		unprocessed: 'Unprocessed',
		processed: 'Processed',
		processing_failed: 'Processing failed',
		order_no: 'Order number',
		order_type: 'Order type',
		domain: 'Domain name',
		order_content: 'Order content',
		pay_method: 'Payment method',
		price: 'Amount',
		pay_status: 'Payment status',
		deal_status: 'Deal Status',
	},
}
