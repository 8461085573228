import { getUrlParams } from '@/utils'
import { Cookies, Local, Session, TOKEN_NAME } from './storage'
import { setWebicon } from '/@/utils/setWebIcon'
import { setThem } from '/@/utils/theme'
import { ElMessage } from 'element-plus'
import { getBrand } from '@/api/common'
import uploadUser from './uploadUserInfo'
import { i18n } from '../i18n'

//验证token
const token = getUrlParams('token')
const url = process.env.NODE_ENV === 'development' ? 'test-account.oemsaas.cn' : location.host
export async function sso() {
	if (token) {
		Cookies.remove() //先移除cookie
		try {
			await getInfo()
			await uploadUser()
			window.location.href = window.location.origin + '/'
		} catch (error) {
			console.log('自动登录失败，错误信息:' + JSON.stringify(error))
		}
	}
}
//获取店铺信息
export async function getInfo() {
	const { t } = i18n.global
	try {
		const { data } = await getBrand(url)
		Session.set(TOKEN_NAME, token)
		Cookies.set(TOKEN_NAME, token)
		document.title = `${t('utils.login')} - ${data?.brand_name}`
		Local.set('account-font-platInfo', data)
		setWebicon(data?.favicon || '')
		setThem() //设置全局主题
	} catch (error) {
		Cookies.remove()
		ElMessage.error(t('utils.msg_store_err'))
	}
}
