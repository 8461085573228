export default {
	statistics: {
		store_title: '店铺统计',
		data_download: '数据下载',
		enter_store: '进入店铺',
		sales_detail: '经营明细',
		cur_account: '当前账号',
		pl_cur_account: '请选择账号',
		pl_timezone: '请选择时区',
		rate: '转化率',
		remark: '备注',
		store_name: '店铺名称',
		order_paid_count: '付款订单数',
		paid_usd: '营业额',
		customer_count: '顾客数',
		download_success: '下载成功',
		back_statistics: '返回店铺统计',
		gmt5: 'GMT-05:00 东部时间（美国/加拿大）',
		gmt8: 'GMT+08:00 北京时间',
		date: '日期',
		sonuser_title: '子账号统计',
		enter_sonuser: '进入子账号',
		rate_tip: '付款订单数/UV',
		mobile_number: '手机号',
		order_count: '订单数',
		auth_account: '授权账号',
		time_options: {
			today: '今天',
			yesterday: '昨天',
			past_7_day: '过去7天',
			past_15_day: '过去15天',
			past_30_day: '过去30天',
			this_week: '本周',
			this_month: '本月',
			last_week: '上周',
			last_month: '上月',
			custom: '自定义',
			recent_10_min: '最近10分钟',
			recent_20_min: '最近20分钟',
			recent_30_min: '最近30分钟',
		},
		start_date: '到期时间开始日期',
		end_date: '到期时间结束日期',
		panel: {
			title: '数据面板',
			last_time: '最后统计时间',
			refresh: '刷新',
			all_store: '所有店铺',
			all_store_tip: '当前账号下的所有店铺',
			prev_time: '同比上一个时间: ',
			remark: '备注: ',
			detail: '查看详情',
			vis_filler: '转化漏斗',
			vis_filler_tip: '通过分析转化漏斗中每个阶段的数据来了解用户行为',
			uv_vis: '访客转化:',
			load_more: '加载更多',
			basic_title1: '营业额',
			basic_tip1: '下单成功的订单总金额',
			basic_title2: '成交订单数',
			basic_tip2: '下单成功的订单总数量',
			basic_title3: '订单转化率',
			basic_tip3: '下单成功的订单总数量/访问人数的百分比',
			norm_title1: '访客数',
			norm_tip1: '访问网站的独立用户数',
			norm_title2: '订单数',
			norm_tip2: '下单成功的订单总数量',
			norm_title3: '顾客数',
			norm_tip3: '下单成功的独立用户数',
			norm_title4: '页面访问量',
			norm_tip4: '所有页面的访问数量',
			norm_title5: '客单价',
			norm_tip5: '下单成功总成交额/总订单量',
			norm_title6: '添加支付数量',
			norm_tip6: '在checkout的Payment页面提交支付信息',
			norm_title7: '支付成功',
			norm_tip7: '支付成功的订单数量',
			visitor: {
				event1: '访问用户',
				tip1: '访问网站的独立用户数',
				rate_tip1: '查看商品/访问用户*100%',
				event2: '查看商品',
				tip2: '查看商品的独立用户数',
				visit_tip2: '查看商品/访问用户*100%',
				rate_tip2: '加购商品/查看商品*100%',
				event3: '加购商品',
				tip3: '把商品加入到购物车的独立用户数',
				visit_tip3: '加购商品/访问用户*100%',
				rate_tip3: '发起结账/加购商品*100%',
				event4: '发起结账',
				tip4: '到达结账页的独立用户数',
				visit_tip4: '发起结账/访问用户*100%',
				rate_tip4: '添加地址/发起结账*100%',
				event5: '添加地址',
				tip5: '在checkout的Information页面提交收货信息，并点击【Continue to Shipping】按钮的独立用户数',
				visit_tip5: '添加地址/访问用户*100%',
				rate_tip5: '添加物流/添加地址*100%',
				event6: '添加物流',
				tip6: '在checkout的Shipping页面添加物流信息，并点击【Continue to Payment 】按钮的独立用户数',
				visit_tip6: '添加物流/访问用户*100%',
				rate_tip6: '添加支付/添加物流*100%',
				event7: '添加支付',
				tip7: '在checkout的Payment页面提交支付信息，并点击【Continue to Payment】按钮的独立用户数',
				visit_tip7: '添加支付/访问用户*100%',
				rate_tip7: '支付成功/添加支付*100%',
				event8: '支付成功',
				tip8: '支付成功的订单数量',
				visit_tip8: '支付成功/访问用户*100%',
			},
		},
		store_start_at: '开店开始时间',
		store_end_at: '开店结束时间',
		select_time: '选择时间范围',
		select_group: '选择分组字段',
		group_type_k1: '比如您有5个店铺且每天都有访问量，选择时间范围6.1~6.3时',
		group_type_k2: '1. 按店铺分组，将导出5条数据，对应每个店铺在这三天内的总数据；',
		group_type_k3: '2. 按日期分组，将导出15条数据，对应每一天下每个店铺的具体数据。',
		group_store: '按店铺分组',
		group_date: '按日期分组',
	},
}
