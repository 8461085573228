import { Module } from 'vuex'
import { Local } from '/@/utils/storage'
// 此处加上 `.ts` 后缀报错，具体原因不详
import { RootStateTypes } from '/@/store/interface/index'
import type { UserData } from '@/api/types/common'

const userInfosModule: Module<Partial<UserData>, RootStateTypes> = {
	namespaced: true,
	state: {},
	mutations: {
		// 设置用户信息
		getUserInfos(state, data: UserData) {
			data = hmRoles(data)
			Object.keys(data).forEach((key) => {
				state[key] = data[key]
			})
		},
	},
	actions: {
		// 设置用户信息
		async setUserInfos({ commit }, data: UserData) {
			if (data) {
				commit('getUserInfos', data)
			} else {
				if (Local.get('account-font-userInfo'))
					commit('getUserInfos', Local.get('account-font-userInfo'))
			}
		},
	},
}

//手动设置权限
const hmRoles = (data: UserData) => {
	let roles = ['admin']
	if (![2].includes(data.loan)) {
		//是否钱包余额角色
		roles.push('wallet')
	}
	//是否显示域名管理和我的订单
	if ([1].includes(data.is_domain)) {
		roles.push('domain')
	}
	//是否显示子账号菜单
	if (data.pid === 0 && data.is_ka === 1) {
		roles.push('sonuser')
	}
	//是否显示发票管理菜单
	if (data.is_invoice === 1) {
		roles.push('invoice')
	}
	//是否显示评论反馈
	if (data.menu_comment_status === 1) {
		roles.push('evaluate')
	}
	//是否显示激励分享一级菜单
	if (data.brand_share_status === 1) {
		roles.push('brand_share_status')
	}
	//是否显示激励分享
	if (data.share_status === 1) {
		roles.push('share')
	}
	return { ...data, roles }
}
export default userInfosModule
