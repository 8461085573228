export default {
	message: {
		title: '消息中心',
		look_msg: '查看消息',
		is_read: '是否已读',
		read: '已读',
		unread: '未读',
		msg_tilte: '消息标题',
		send_at: '发送时间',
		msg_type: '消息类型',
		sys_msg: '系统消息',
		ord_msg: '普通消息',
		msg_desc1: '此操作将批量已读选中的消息，是否继续？',
		msg_desc2: '此操作将已读所有的消息，是否继续？',
		msg_desc3: '此操作将永久删除选中的消息，是否继续？',
		msg_desc4: '此操作将永久删除所有的消息，是否继续？',
		batch_title1: '批量已读',
		batch_title2: '全部已读',
		batch_title3: '批量删除',
		batch_title4: '全部删除',
		auto_clean: '自动清理',
		clean_read: '清除已读消息',
		no_clean: '不清除',
	},
}
