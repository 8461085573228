import { Module } from 'vuex'
import { Local } from '/@/utils/storage.ts'
import { StoreInfoRootState, StoreInfoState, RootStateTypes } from '/@/store/interface/index'

const storeInfoModule: Module<StoreInfoRootState, RootStateTypes> = {
	namespaced: true,
	state: {
		storeInfo: {} as StoreInfoState,
	},
	mutations: {
		// 设置用户信息
		getStoreInfo(state: any, data: object) {
			state.storeInfo = data
		},
	},
	actions: {
		// 设置用户信息
		async setStoreInfo({ commit }, data: object) {
			if (data) {
				commit('getStoreInfo', data)
			} else {
				if (Local.get('storeInfo')) commit('getStoreInfo', Local.get('storeInfo'))
			}
		},
	},
}

export default storeInfoModule
