import { Module } from 'vuex'
import { Local } from '/@/utils/storage'
// 此处加上 `.ts` 后缀报错，具体原因不详
import { RootStateTypes } from '/@/store/interface/index'
import type { BrandDate } from '@/api/types/common'
const platInfosModule: Module<Partial<BrandDate>, RootStateTypes> = {
	namespaced: true,
	state: {},
	mutations: {
		// 设置招商平台信息
		getPlatInfos(state, data: BrandDate) {
			Object.keys(data).forEach((key) => {
				state[key] = data[key]
			})
		},
	},
	actions: {
		// 设置设置招商平台信息
		async setPlatInfos({ commit }, data: BrandDate) {
			if (data) {
				commit('getPlatInfos', data)
			} else {
				if (Local.get('account-font-platInfo'))
					commit('getPlatInfos', Local.get('account-font-platInfo'))
			}
		},
	},
}

export default platInfosModule
