export default {
	invoice: {
		title: 'Invoices',
		title_manage: 'Invoice titles',
		apply_invoice: 'Apply',
		max_invoice:
			'A maximum of 5 invoices can be applied for each month, please select the required invoice',
		in_progress: 'Pending',
		processing: 'Processing',
		invoice_issued: 'Completed',
		invoice_no: 'Invoice number',
		invoice_org: 'Invoice title',
		pl_invoice_org: 'Please enter the title of the invoice',
		email: 'Email',
		valid_email: 'Email is invalid',
		pl_email: 'Please enter the email address',
		email_tip: 'The issued electronic invoice will be sent to this email address by email',
		price: 'Amount',
		apply_at: 'applied at',
		apply_status: 'Status',
		remark: 'Remark',
		msg_del: 'Are you sure to delete this piece of data?',
		back_manage: 'Back to invoice management',
		prev: 'Previous step',
		next: 'Next',
		save: 'Save',
		bills: 'Bills',
		selected_amount: 'Selected billing amount:',
		trade_no: 'Number',
		recharge_amount: 'Amount',
		cny_amount: 'Amount',
		pay_method: 'Payment method',
		choose_bill: 'Choose the recharge bill that needs to be invoiced',
		write_info: 'Fill in billing information',
		msg_invoice: 'The billing will be confirmed soon, please check the information is correct?',
		look_info: 'View information',
		apply_bill: 'Invoicing Bill',
		apply_bill_amount: 'Billing amount:',
		add_invoice_info: 'Add new title',
		add_success: 'Add success',
		form_desc1:
			'The value-added tax general invoice (electronic) content is "technical service", which cannot be changed',
		form_desc2: `Please confirm and fill in the invoice information with your company's finance company, and send it to your company's mailbox within one week after submission, please wait patiently, it may be delayed in case of holidays or special circumstances`,
		form_desc3:
			'Note: Once the invoice is issued, it cannot be re-issued if it is not due to our company',
		pl_choose_info: 'Please add or choose invoice information',
		invoice_info: 'Invoice information',
		no_invoice_info: 'There is no invoice information, please add it first!',
		invoice_title_manage: 'Title',
		tax_no: 'Taxpayer Identification Number:',
		no_head: 'No data, please add title',
		add_new_head: 'Add new title',
		msg_del_head: 'Are you sure to delete this piece of data?',
		all_status: 'All',
		tax_no_item: 'Taxpayer Identification Number',
		pl_tax_no_item: 'Please enter the taxpayer identification number',
		invoice_type: 'Invoice type',
		invoice_type_tip: 'The invoice is expected to be processed in 7 working days',
		pl_invoice_type: 'Please select the invoice type',
		reg_address: 'Company address',
		pl_reg_address: 'Please enter the business registration address',
		reg_phone: 'Company phone number',
		pl_reg_phone: 'Please enter the business phone number',
		bank_name: 'Bank name',
		pl_bank_name: 'Please enter the bank name',
		bank_account: 'Bank account',
		pl_bank_account: 'Please enter the bank account number',
	},
}
