export default {
	dashboard: {
		overview: 'Overview',
		login_at: 'login time',
		hello: 'Hello!',
		last_at: 'Last login at:',
		account_type: 'account type',
		ka_account: 'KA account',
		store_num: 'Number of stores',
		available: 'Available',
		expiring_soon: 'Expiring',
		balance: 'Balance',
		bills: 'Bills',
		pending_pay: 'Pending',
		paid: 'Paid',
		pay_failed: 'Payment failed',
		cancelled: 'Cancelled',
		messages: 'Message',
		not_read: 'Unread',
		readed: 'Read',
		domain: 'Domain',
		expiring_tip:
			'The expiration time is less than one month, please renew in time to avoid normal use',
		business: 'Business market',
		cur_account: 'Current account',
		revenue: 'Turnover',
		trans_order_num: 'Number of transaction orders',
		visitor: 'Visitors',
		page_visitor: 'Page visits',
		customers: 'Customers',
		order_num: 'Order number',
		account: 'Account information',
		online_store: 'Online store',
		use_store: 'Remaining store quota',
		store_tip: 'Stores that subscribe to a version less than 15 days old',
		pen: 'items',
		active_store: 'Active',
		last_open: 'Available',
		fa2: 'Two-step Verification (2FA) is recommended to increase account security. When logging in, in addition to the password, you also need to provide a security key for secondary login verification. ',
		to_set: 'Go to Settings ',
		store_num_tips:
			'If your store has not generated orders in the past month, you can try to close these stores without orders for a long time to release the space ',
	},
}
