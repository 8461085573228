import { nextTick } from 'vue'
import router from '/@/router/index'
import { Local, Session } from '/@/utils/storage'
import { i18n } from '../i18n'

/**
 * 设置浏览器标题
 */
export function useTitle() {
	const { t } = i18n.global
	return () => {
		nextTick(() => {
			const webTitle = t(router.currentRoute.value.meta.title as string)
			const globalTitle: string = Local.get('account-font-platInfo')?.brand_name || ''
			document.title = webTitle + ' - ' + globalTitle
			Session.set('title', document.title)
		})
	}
}
export function SesstionTitle() {
	return (document.title = Session.get('title') || 'OEMSAAS')
}
