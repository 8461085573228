export default {
	common: {
		pl_search: '请输入搜索条件',
		handle: '操作',
		ends_at: '到期时间',
		created_at: '创建时间',
		pay_at: '支付时间',
		action_success: '操作成功',
		tip: '提示',
		cancel: '取消',
		confirm: '确定',
		unpaid: '未支付',
		paid: '已支付',
		payment_failed: '支付失败',
		cancelled: '已作废',
		edit_success: '修改成功',
		pl_start_at: '选择开始时间',
		pl_ends_at: '选择结束时间',
		back: '返回',
		msg_edit: '本次编辑的内容尚未保存，确定放弃修改吗？',
		msg_edit_title: '放弃当前更改内容？',
		submit_leave: '确定离开当前页面吗',
		start_at: '开始时间',
		end_at: '结束时间',
		msg_title: '确认执行此操作？',
		warning: '警告',
		close: '关闭',
		copy_success: '复制成功',
		copy_failed: '复制失败',
		more: '查看更多',
		gmt5: '(GMT-05:00) 东部时间（美国和加拿大）',
		gmt8: '(GMT+08:00) 北京时间',
		'gmt-7': '(GMT-07:00) 亚利桑那时间',
		'gmt-8': '(GMT-08:00) 太平洋时间 （美国和加拿大）',
		'gmt-9': '(GMT-09:00) 阿拉斯加时间',
		'gmt-10': '(GMT+00:00)爱丁堡、伦敦',
		'gmt-11': '(GMT-11:00) 美属萨摩亚',
		'gmt-12': '(GMT-12:00) 国际日期变更线西时间',
		'gmt-13': '(GMT-10:00) 夏威夷',
		'gmt-14': '(GMT-07:00) 亚利桑那',
		'gmt-15': '(GMT-06:00) 中部时间（美国和加拿大）',
		'gmt-16': '(GMT-03:00) 巴西利亚',
		'gmt-17': '(GMT-02:00) 大西洋中部',
		'gmt-18': '(GMT-01:00) 亚速尔群岛',
		'gmt-19': '(GMT+01:00) 阿姆斯特丹',
		'gmt-20': '(GMT+02:00) 雅典',
		'gmt-21': '(GMT+03:00) 莫斯科、圣彼得堡',
		'gmt-22': '(GMT+04:00) 阿布扎比、马斯喀特',
		'gmt-23': '(GMT+05:00) 伊斯兰堡、卡拉奇',
		'gmt-24': '(GMT+06:00) 阿斯塔纳、达卡',
		'gmt-25': '(GMT+07:00) 曼谷、河内',
		'gmt-26': '(GMT+09:00) 东京、首尔',
		'gmt-27': '(GMT+10:00) 堪培拉、墨尔本',
		'gmt-28': '(GMT+11:00) 马加丹',
		'gmt-29': '(GMT+12:00) 奥克兰、惠灵顿',
		'gmt-30': '(GMT+13:00) 萨摩亚',
		'gmt-31': '(GMT-04:00) 大西洋时间（加拿大）',
		man_refresh: '手动刷新',
		not_upload: '未上传',
		uploaded: '已上传',
		upload_fail: '上传失败',
	},
}
