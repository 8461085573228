import { Module } from 'vuex'
import { RootStateTypes, RouteConf } from '/@/store/interface/index'

const routeConfModule: Module<Partial<RouteConf>, RootStateTypes> = {
	namespaced: true,
	state: {
		key: '0',
	},
	mutations: {
		setKey(state, data) {
			state.key = data
		},
	},
}

export default routeConfModule
