import { createApp } from 'vue'
import { i18n } from '@/i18n'
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import mitt from 'mitt'
import App from './App.vue'
import router from './router'
import { store, key } from './store'
import '/@/theme/index.scss'

const app = createApp(App)
app.config.globalProperties.mittBus = mitt()
app.use(router).use(store, key).use(i18n).use(ElementPlus, { i18n: i18n.global.t }).mount('#app')
