import request from '@/utils/request'
import { Ref } from 'vue'
import { Res } from '@/types/common'
import { BrandDate, UserData, Currencies, ShopList, CountriesData, StoreData } from './types/common'
//获取品牌数据
export const getBrand = (url: string, loading?: Ref<boolean>) => {
	return request({
		url: `/brand/${url}`,
		loading,
	}) as Promise<Res<BrandDate>>
}

//获取用户信息
export const getUser = (loading?: Ref<boolean>) => {
	return request({
		url: '/userinfo',
		loading,
	}) as Promise<Res<UserData>>
}

//获取国家货币
export const getCurrencies = (loading?: Ref<boolean>) => {
	return request({
		url: '/currencies',
		loading,
	}) as Promise<Res<Currencies[]>>
}

//获取所有店铺
export const getAllstores = (params?: any, loading?: Ref<boolean>) => {
	return request({
		url: '/allstores',
		loading,
		params,
	}) as Promise<Res<ShopList[]>>
}

//获取所有国家
export const getCountries = (loading?: Ref<boolean>) => {
	return request({
		url: '/countries',
		loading,
	}) as Promise<Res<CountriesData>>
}

//获取店铺登录token
export const getStoreToken = (id, loading?: Ref<boolean>) => {
	return request({
		url: `/stores/token/${id}`,
		loading,
	}) as Promise<Res<StoreData>>
}

interface Token {
	token: string
}
//获取子账号token
export const getSubUserToken = (id: number | string, loading?: Ref<boolean>) => {
	return request({
		url: `/subaccount/${id}/token`,
		loading,
	}) as Promise<Res<Token>>
}

//是否需要弹出邀请评论
export const invite = (params: { userId: number }) => {
	return request({
		url: `/comments_invite`,
		params,
	}) as Promise<Res<boolean>>
}

//是否需要弹出邀请评论
export const invitePost = (data: { userId: number }) => {
	return request({
		url: `/comments_invite/cancel`,
		data,
		method: 'POST',
	})
}

//修改用户头像
export const pic = (data: { pic: string }, loading: Ref<boolean>) => {
	return request({
		url: `/user/pic`,
		data,
		loading,
		method: 'put',
	})
}

//修改用户头像
export const setLanguage = (data: { language: 'zh-cn' | 'en' }, loading?: Ref<boolean>) => {
	return request({
		url: `/user/language`,
		data,
		loading,
		method: 'PUT',
	})
}
