export default {
	statistics: {
		store_title: 'Store Statistics',
		data_download: 'Download',
		enter_store: 'Enter the store',
		sales_detail: 'Business details',
		cur_account: 'Current account',
		pl_cur_account: 'Please select an account',
		pl_timezone: 'Please select a time zone',
		rate: 'Conversion',
		remark: 'Remark',
		store_name: 'Name',
		order_paid_count: 'Paid orders',
		paid_usd: 'Total sales',
		customer_count: 'Customers',
		download_success: 'Download succeeded',
		back_statistics: 'Return to store statistics',
		gmt5: 'GMT-05:00 Eastern Time (US/Canada)',
		gmt8: 'GMT+08:00 Beijing time',
		date: 'Date',
		sonuser_title: 'Account Statistics',
		enter_sonuser: 'Enter account',
		rate_tip: 'Payment order number/UV',
		mobile_number: 'Phone',
		order_count: 'Orders',
		auth_account: 'Authorization Account',
		time_options: {
			today: 'Today',
			yesterday: 'Yesterday',
			past_7_day: 'Last 7 days',
			past_15_day: 'Last 15  days',
			past_30_day: 'Last 30 days',
			this_week: 'Week to date',
			this_month: 'Month to date',
			last_week: 'Last week',
			last_month: 'Last month',
			custom: 'Custom',
			recent_10_min: 'Last 10 min.',
			recent_20_min: 'Last 20 min.',
			recent_30_min: 'Last 30 min.',
		},
		start_date: 'Time of expiration Start date',
		end_date: 'Time of expiration End date',
		panel: {
			title: 'Data Panel',
			last_time: 'Last Statistics Time',
			refresh: 'Refresh',
			all_store: 'All Stores',
			all_store_tip: 'All stores under the current account',
			prev_time: 'Year-over-Year for the Previous Time: ',
			remark: 'Remark: ',
			detail: 'View Details',
			vis_filler: 'Conversion Funnel',
			vis_filler_tip:
				'Understand user behavior by analyzing data at each stage of the conversion funnel',
			uv_vis: 'Visitor Conversion:',
			load_more: 'Load More',
			basic_title1: 'Turnover',
			basic_tip1: 'Total amount of successfully placed orders',
			basic_title2: 'Completed Orders',
			basic_tip2: 'Total number of successfully placed orders',
			basic_title3: 'Order Conversion Rate',
			basic_tip3: 'Percentage of successfully placed orders to the number of visitors',
			norm_title1: 'Visitors',
			norm_tip1: 'Number of unique users visiting the website',
			norm_title2: 'Order Count',
			norm_tip2: 'Total number of successfully placed orders',
			norm_title3: 'Customer Count',
			norm_tip3: 'Number of unique users who placed successful orders',
			norm_title4: 'Page Views',
			norm_tip4: 'Total number of visits to all pages',
			norm_title5: 'Customer price',
			norm_tip5: 'Total turnover of successful orders divided by the total number of orders',
			norm_title6: 'Add payment',
			norm_tip6: 'Submission of payment information on the Payment page during checkout',
			norm_title7: 'Payment Success',
			norm_tip7: 'Number of orders successfully paid',
			visitor: {
				event1: 'Visiting Users',
				tip1: 'Number of unique users visiting the website',
				rate_tip1: 'Viewed Products / Visiting Users * 100%',
				event2: 'Viewed Products',
				tip2: 'Number of unique users viewing products',
				visit_tip2: 'Viewed Products / Visiting Users * 100%',
				rate_tip2: 'Added to Cart / Viewed Products * 100%',
				event3: 'Added to Cart',
				tip3: 'Number of unique users adding products to the shopping cart',
				visit_tip3: 'Added to Cart / Visiting Users * 100%',
				rate_tip3: 'Initiated Checkout / Added to Cart * 100%',
				event4: 'Initiated Checkout',
				tip4: 'Number of unique users reaching the checkout page',
				visit_tip4: 'Initiated Checkout / Visiting Users * 100%',
				rate_tip4: 'Added Address / Initiated Checkout * 100%',
				event5: 'Added Address',
				tip5: "Number of unique users submitting shipping information on the Information page during checkout and clicking the 'Continue to Shipping' button",
				visit_tip5: 'Added Address / Visiting Users * 100%',
				rate_tip5: 'Added Shipping / Added Address * 100%',
				event6: 'Added Shipping',
				tip6: "Number of unique users adding shipping information on the Shipping page during checkout and clicking the 'Continue to Payment' button",
				visit_tip6: 'Added Shipping / Visiting Users * 100%',
				rate_tip6: 'Added Payment / Added Shipping * 100%',
				event7: 'Added Payment',
				tip7: "Number of unique users submitting payment information on the Payment page during checkout and clicking the 'Continue to Payment' button",
				visit_tip7: 'Added Payment / Visiting Users * 100%',
				rate_tip7: 'Payment Success / Added Payment * 100%',
				event8: 'Payment Success',
				tip8: 'Number of successfully paid orders',
				visit_tip8: 'Payment Success / Visiting Users * 100%',
			},
		},
		store_start_at: 'Store start time',
		store_end_at: 'Store end time',
		select_time: 'Select Time Range',
		select_group: 'Select Group Field',
		group_type_k1:
			'For example, if you have 5 stores with daily visits, and you select the time range from 6.1 to 6.3:',
		group_type_k2:
			'1. Group by store will export 5 records, each representing the total data for each store over these three days;',
		group_type_k3:
			'2. Group by date will export 15 records, each representing the specific data for each store on each day.',
		group_store: 'Group by Store',
		group_date: 'Group by Date',
	},
}
