export default {
	message: {
		title: 'Messages',
		look_msg: 'View message',
		is_read: 'Status',
		read: 'Read',
		unread: 'Unread',
		msg_tilte: 'Title',
		send_at: 'Sent at',
		msg_type: 'Type',
		sys_msg: 'System message',
		ord_msg: 'Ordinary message',
		msg_desc1:
			'This operation will mark selected messages as read in bulk. Do you want to continue?',
		msg_desc2: 'This operation will mark all messages as read. Do you want to continue?',
		msg_desc3: 'This operation will permanently delete selected messages. Do you want to continue?',
		msg_desc4: 'This operation will permanently delete all messages. Do you want to continue?',
		batch_title1: 'Mark as read (batch)',
		batch_title2: 'Mark all as read',
		batch_title3: 'Delete (batch)',
		batch_title4: 'Delete all',
		auto_clean: 'Auto clean',
		clean_read: 'Clean read messages',
		no_clean: 'Do not clean',
	},
}
