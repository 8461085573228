import { getUser } from '../api/common'
import { store } from '../store'
import router from '@/router'
import { Cookies, Local, TOKEN_NAME } from './storage'

//更新用户信息
export default async function uploadUser() {
	if (!Cookies.get(TOKEN_NAME)) return
	const { data } = await getUser()

	Local.set('account-font-userInfo', data) //设置用户信息到缓存

	store.dispatch('userInfos/setUserInfos', data)
	if (data?.is_active === 0) {
		//账号审核中
		setTimeout(() => {
			router.push('/audit')
		})
	}
}
