export default {
	invoice: {
		title: '发票管理',
		title_manage: '抬头管理',
		apply_invoice: '申请开票',
		max_invoice: '每月最多可申请开具5张发票，请选择好需要的开票账单',
		in_progress: '申请中',
		processing: '处理中',
		invoice_issued: '开票完成',
		invoice_no: '开票编号',
		invoice_org: '发票抬头',
		pl_invoice_org: '请输入发票抬头',
		email: '收件邮箱',
		valid_email: '邮箱不合法',
		pl_email: '请输入收件邮箱',
		email_tip: '开具的电子发票我们将通过邮件的方式发送至此邮箱',
		price: '发票金额',
		apply_at: '申请时间',
		apply_status: '申请状态',
		remark: '处理备注',
		msg_del: '确认删除此条数据吗?',
		back_manage: '返回发票管理',
		prev: '上一步',
		next: '下一步',
		save: '保存',
		bills: '账单',
		selected_amount: '已选开票金额：',
		trade_no: '流水号',
		recharge_amount: '充值金额',
		cny_amount: '人民币金额',
		pay_method: '支付方式',
		choose_bill: '选择需要开票的充值账单',
		write_info: '填写开票信息',
		msg_invoice: '即将确认开票,请核对信息无误?',
		look_info: '查看信息',
		apply_bill: '开票账单',
		apply_bill_amount: '开票金额：',
		add_invoice_info: '添加新抬头信息',
		add_success: '新增成功',
		form_desc1: '增值税普通发票（电子）内容为”技术服务“，不可变更',
		form_desc2:
			'发票信息请与贵司财务确认填写，提交后一周内发至贵司邮箱，请耐心等待，如遇节假日或特殊情况可能会延迟',
		form_desc3: '注意:发票一经开具，非我司原因请恕无法重开',
		pl_choose_info: '请添加或者选择发票信息',
		invoice_info: '发票信息',
		no_invoice_info: '暂无发票信息请先添加!',
		invoice_title_manage: '抬头管理',
		tax_no: '纳税人识别号：',
		no_head: '暂无数据,请添加抬头',
		add_new_head: '添加新抬头信息',
		msg_del_head: '确认删除此条数据吗?',
		all_status: '全部状态',
		tax_no_item: '纳税人识别号',
		pl_tax_no_item: '请输入纳税人识别号',
		invoice_type: '发票类型',
		invoice_type_tip: '发票预计在7个工作日后可处理完成',
		pl_invoice_type: '请选择发票类型',
		reg_address: '企业注册地址',
		pl_reg_address: '请输入企业注册地址',
		reg_phone: '企业电话',
		pl_reg_phone: '请输入企业电话',
		bank_name: '开户行名称',
		pl_bank_name: '请输入开户行名称',
		bank_account: '开户行账号',
		pl_bank_account: '请输入开户行账号',
	},
}
