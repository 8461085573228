export default {
	orders: {
		my_order: '我的订单',
		pl_order_no: '请输入订单编号',
		unprocessed: '未处理',
		processed: '已处理',
		processing_failed: '处理失败',
		order_no: '订单编号',
		order_type: '订单类型',
		domain: '域名',
		order_content: '订单内容',
		pay_method: '支付方式',
		price: '金额',
		pay_status: '支付状态',
		deal_status: '处理状态',
	},
}
