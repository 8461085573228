export default {
	components: {
		edit: 'Edit',
		preview: 'Preview',
		copy: 'Copy',
		delete: 'Delete',
		title: 'Title',
		edit_table: 'Edit table',
		edit_table_info:
			'Please select at least 5 table header fields to display, and drag to change the order.',
		check_all: 'Check all',
		recover_default: 'Recover default configuration',
		no_data: 'No data',
		checked: 'Selected',
		more: 'More',
		option: 'Option',
		pl_choose: 'Please choose',
		pl_search: 'Please enter search content',
		pl_country: 'Please select country (searchable)',
		all_store: 'All stores',
		piece: '',
		enter_create_tag: 'Press Enter to confirm. You can enter multiple items',
	},
	utils: {
		day: 'Day',
		one: 'One',
		two: 'Two',
		three: 'Three',
		four: 'Four',
		five: 'Five',
		six: 'Six',
		week1: 'Week',
		week2: 'Week',
		no: 'No.',
		the_quarter: 'Quarter',
		just: 'Just now',
		seconds_ago: 'Seconds ago',
		mintues_ago: 'Minutes ago',
		hours_ago: 'Hours ago',
		days_ago: 'Days ago',
		greeting_axis1: 'Good morning',
		greeting_axis2: 'Good morning',
		greeting_axis3: 'Good morning',
		greeting_axis4: 'Good afternoon',
		greeting_axis5: 'Good afternoon',
		greeting_axis6: 'Good evening',
		greeting_axis7: 'Good evening',
		greeting_axis8: 'Good night',
		set_timezone: 'Set timezone',
		msg_store_err: 'Store information is missing. Please enter the correct store domain name.',
		login: 'Login',
		valid_amount_maxmin: 'Enter up to 9 digits and 2 decimal places',
	},
	error: {
		expired: 'Shop expired',
		not_found: 'Page not found',
		contact_admin: 'Please contact administrator or enter address again',
		back_home: 'Back to home',
		account_review: 'Account under review',
		contact_worker: 'Please contact worker',
		pass_review: 'Has passed review?',
		retry_login: 'Retry login',
	},
}
