import { createI18n } from 'vue-i18n'
import zhcnLocale from 'element-plus/lib/locale/lang/zh-cn'
import enLocale from 'element-plus/lib/locale/lang/en'
import zhcnLang from '@/i18n/lang/zh-cn'
import enLang from '@/i18n/lang/en'
import { Local } from '../utils/storage'

const enModules = import.meta.globEager('./pages/**/en.ts')
const zhCnModules = import.meta.globEager('./pages/**/zh-cn.ts')

const getLangFiles = (modules: any) => {
	let message = {}
	for (const key in modules) {
		const module = modules[key].default
		Object.assign(message, module)
	}
	return message
}

/**
 * 说明：
 * /src/i18n/lang 下的 ts 为框架的国际化内容
 * /src/i18n/pages 下的 ts 为各界面的国际化内容
 */
const messages = {
	[zhcnLocale.name]: {
		...zhcnLocale,
		...zhcnLang,
		...getLangFiles(zhCnModules),
	},
	[enLocale.name]: {
		...enLocale,
		...enLang,
		...getLangFiles(enModules),
	},
}

// 导出语言国际化
export const i18n = createI18n({
	locale: Local.get('account-font-userInfo')?.language || 'zh-cn',
	fallbackLocale: zhcnLocale.name,
	messages,
})
