export default {
	wallet: {
		wallet_balance: '钱包余额',
		alert: '余额报警',
		recharge_now: '立即充值',
		recharge_now_tip: '请联系客户经理进行充值',
		trade_no: '流水号',
		amount: '变动金额',
		balance: '余额',
		pay_method: '支付方式',
		booking_alert: '预约预警',
		sms: '当钱包余额低于配置金额时，将会以短信的形式通知',
		sms_tip: '短信每三天最多发送一次',
		notice_amount: '通知金额',
		pl_notice_amount: '请输入通知金额',
		pl_num: '请输入数字',
		pay: '支付',
		pay_remark: '付款完成前请不要关闭此窗口，完成付款后请根据您的情况点击下面的按钮',
		payment_completed: '已经完成付款',
		back_payment: '返回选择其它支付方式',
		card_sign: '银行卡签约',
		choose_card: '选择银行卡',
		re_sign: '重新签约',
		cancel_sign: '取消签约',
		no_card: '暂无银行卡信息',
		add_card: '添加银行卡',
		debit_sign: '目前仅支持个人储蓄卡签约',
		card_no: '银行卡号:',
		pl_card_no: '请输入银行卡号',
		card_name: '持卡人姓名:',
		pl_card_name: '请输入持卡人姓名',
		cert_no: '证件号:',
		pl_cert_no: '请输入证件号',
		mobile_no: '银行预留手机号:',
		pl_mobile_no: '请输入银行预留手机号',
		valid_mobile_no: '请输入正确的手机号',
		expire_date: '银行卡有效期:',
		pl_expire_date: '请选择银行卡有效期',
		cvv: '银行卡校验值:',
		pl_cvv: '请输入银行卡校验值',
		vercode: '银行卡验证码:',
		pl_vercode: '请输入银行卡验证码',
		confirm_info: '提交银行卡信息',
		confirm_sign: '确认签约',
		pay_amount: '支付金额:',
		confirm_pay: '确认支付',
		pl_code: '请输入验证码',
		recharge_success: '充值成功',
		submit_success: '提交成功',
		sign_success: '签约成功',
		sign_msg: '确定取消签约吗？',
		cancel_success: '取消成功',
		back: '返回钱包余额',
		recharge_amount: '充值金额',
		amount_field: '金额:',
		max_amount_field: '最大金额100000',
		scan_pay: '扫一扫付款（元）',
		pay_success: '付款完成？',
		click_look: '点击查看',
		valid_amount: '充值金额不能为空',
		valid_amount_min: '充值金额不能小于100USD',
		excel: '导出excel',
	},
}
