export default {
	wallet: {
		wallet_balance: 'Balance',
		alert: 'Balance alert',
		recharge_now: 'Recharge',
		recharge_now_tip: 'Please contact the account manager to recharge',
		trade_no: 'Number',
		amount: 'Amount',
		balance: 'Balance',
		pay_method: 'Payment method',
		booking_alert: 'Appointment alert',
		sms: 'When the wallet balance is lower than the configured amount, it will be notified in the form of SMS',
		sms_tip: 'SMS can be sent at most once every three days',
		notice_amount: 'Notification amount',
		pl_notice_amount: 'Please enter the notice amount',
		pl_num: 'Please enter a number',
		pay: 'Pay',
		pay_remark:
			'Please do not close this window before the payment is completed, please click the button below according to your situation after the payment is completed',
		payment_completed: 'Payment has been completed',
		back_payment: 'Back to choose other payment methods',
		card_sign: 'Bank card signing',
		choose_card: 'Choose a bank card',
		re_sign: 'Re-sign',
		cancel_sign: 'Cancel the contract',
		no_card: 'No bank card information',
		add_card: 'Add bank card',
		debit_sign: 'Currently only supports personal savings card signing',
		card_no: 'Bank card number:',
		pl_card_no: 'Please enter the bank card number',
		card_name: 'Cardholder name:',
		pl_card_name: `Please enter the cardholder's name`,
		cert_no: 'Certificate number:',
		pl_cert_no: 'Please enter the certificate number',
		mobile_no: 'The mobile phone number reserved by the bank:',
		pl_mobile_no: 'Please enter the mobile phone number reserved by the bank',
		valid_mobile_no: 'Please enter a correct mobile number',
		expire_date: 'Bank card validity period:',
		pl_expire_date: 'Please select the expiration date of the bank card',
		cvv: 'Bank card verification value:',
		pl_cvv: 'Please enter the bank card verification value',
		vercode: 'Bank card verification code:',
		pl_vercode: 'Please enter the bank card verification code',
		confirm_info: 'Submit bank card information',
		confirm_sign: 'Confirm signing',
		pay_amount: 'Payment amount:',
		confirm_pay: 'Confirm payment',
		pl_code: 'Please enter the verification code',
		recharge_success: 'Recharge successful',
		submit_success: 'Submit successfully',
		sign_success: 'Signed successfully',
		sign_msg: 'Are you sure to cancel the contract? ',
		cancel_success: 'Cancel successfully',
		back: 'Return wallet balance',
		recharge_amount: 'Amount',
		amount_field: 'Amount:',
		max_amount_field: 'Maximum amount 100000',
		scan_pay: 'Scan to pay (yuan)',
		pay_success: 'Payment completed? ',
		click_look: 'Click to view',
		valid_amount: 'The recharge amount cannot be empty',
		valid_amount_min: 'The recharge amount cannot be less than 100USD',
		excel: 'Exporting to excel',
	},
}
