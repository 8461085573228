export default {
	components: {
		edit: '编辑',
		preview: '预览',
		copy: '复制',
		delete: '删除',
		title: '标题',
		edit_table: '编辑表头',
		edit_table_info: '请选择要显示的至少 5 个表头字段，拖动可更换顺序。',
		check_all: '全选',
		recover_default: '恢复默认配置',
		no_data: '暂无数据',
		checked: '已选中',
		more: '更多',
		option: '选项',
		pl_choose: '请选择',
		pl_search: '请输入搜索内容',
		pl_country: '请选择国家(输入可搜索)',
		all_store: '全部店铺',
		piece: '个',
		enter_create_tag: '回车确认，可输入多个',
	},
	utils: {
		day: '日',
		one: '一',
		two: '二',
		three: '三',
		four: '四',
		five: '五',
		six: '六',
		week1: '星期',
		week2: '周',
		no: '第',
		the_quarter: '季度',
		just: '刚刚',
		seconds_ago: '秒前',
		mintues_ago: '分钟前',
		hours_ago: '小时前',
		days_ago: '天前',
		greeting_axis1: '凌晨好',
		greeting_axis2: '早上好',
		greeting_axis3: '上午好',
		greeting_axis4: '中午好',
		greeting_axis5: '下午好',
		greeting_axis6: '傍晚好',
		greeting_axis7: '晚上好',
		greeting_axis8: '夜里好',
		set_timezone: '设置时区',
		msg_store_err: '店铺信息缺失请输入正确的店铺域名',
		login: '登录',
		valid_amount_maxmin: '最多输入9位整数，2位小数',
	},
	error: {
		expired: '店铺过期',
		not_found: '找不到页面',
		contact_admin: '请联系管理员或重新输入地址',
		back_home: '点此回到首页',
		account_review: '账号审核中',
		contact_worker: '请联系工作人员',
		pass_review: '已通过审核?',
		retry_login: '重新登录',
	},
}
