export default {
	login: {
		help_center: 'Help Center',
		terms_service: 'Terms of Service',
		login: 'Login',
		reg: 'Registration',
		pl_phone: 'Please enter your phone number',
		pl_password: 'Please enter a password',
		forget_password: 'Forget password',
		login_success: 'Login successful',
		pl_code: 'Please enter the verification code',
		not_empty: 'Cannot be empty',
		pl_pass_again: 'Please enter the password again',
		confirm_edit: 'Confirm the modification',
		back_login: 'Back to login',
		valid_phone: 'The mobile phone number is invalid',
		valid_pass_len: 'Password length is 7-18',
		pl_shop_name: 'Please enter the store name',
		pl_coupon: 'Please enter the coupon code (optional)',
		i_agree: 'I have read and agree',
		terms_policies: 'Terms and Policies',
		reg_success: 'Registration successful',
		get_code: 'Get SMS code',
		phone_error: 'The phone number is invalid, please check',
		send_code_success: 'SMS sent successfully, please pay attention to the phone information',
		area_code: 'Area code',
		账号或密码错误: 'Incorrect account or password',
		用户不存在: 'User does not exist',
		不能为空: 'Cannot be empty',
		必须是数字: 'Must be a number',
		手机号码格式错误: 'Invalid phone number format',
		用户已存在: 'User already exists',
		两次密码输入不一致: 'Passwords do not match',
		手机验证码错误或失效: 'Incorrect or expired phone verification code',
		优惠码无效: 'Invalid discount code',
		优惠码已过期: 'Expired discount code',
		密码错误: 'Incorrect password',
		pl_category: 'Please select a product category',
		optional: 'Optional',
		pl_pid: 'Please select Master account',
		valid_pass_rule:
			'The password should be at least 7 characters long and contain at least numbers and letters',
		free: 'No login for 7 days ',
		msg_sms: 'SMS validation ',
		verification: 'verify ',
		user: 'To ensure account ',
		code: 'is your own operation, please enter the verification code ',
		pl_graph_code: 'Please enter the graph verification code',
	},
}
