export default {
	sonuser: {
		title: '子账号管理',
		enter_account: '进入子账号',
		edit_remark: '修改信息',
		untie: '解绑子账号',
		edit_pass: '修改密码',
		pl_pass: '请输入密码',
		phone: '手机',
		store_num: '当前店铺在线数量',
		reg_time: '注册时间',
		remark: '备注',
		pl_remark: '请输入备注',
		invite: '邀请子账号',
		pl_phone: '请输入手机号',
		pl_code: '请输入验证码',
		pl_confirm_pass: '请确认密码',
		pl_pass_diff: '两次密码输入不一致',
		reg_success: '注册成功',
		send_success: '验证码发送成功',
		account_desc1:
			'邀请子账号：您可以邀请其他用户注册为您的子账号，子账号KA有效期及佣金与主账号相同，最多可以拥有',
		account_desc2: '当前钱包余额',
		account_desc3: '个子账号，如超出则无法邀请注册',
		account_desc4: '仅供自己内部团队使用，禁止转手销售、借用等，一经发现封号/清0',
		invite_person: '当前剩余可邀请：',
		user_phone: '用户手机号',
		msg_code: '短信验证码',
		pl_msg_code: '请输入短信验证码',
		retry: '秒后重试',
		get_msg_code: '获取验证码',
		login_pass: '登录密码',
		pl_login_pass: '请输入登录密码',
		confirm_pass: '确认密码',
		piece: '个',
		online_num: '最大在线店铺数',
		online_num_tip: '设置0时，与主账号在线店铺数一致',
		pl_online_num: '请输入最大在线店铺数',
		sonuser_tip: '子账号在线店铺数量计入主账号在线店铺数量',
		ka_tip:
			'所有店铺需在KA有效期内使用，到期后将无法继续使用。为避免对您的业务造成影响，请及时联系商务团队续费',
		ka_global: '您的KA有效期已不足 {time}，到期后所有店铺将无法继续使用，请及时联系商务团队续费',
		day: '天',
		hours: '小时',
		ka_expired_global: '您的KA有效期已过期，请及时联系商务团队续费',
	},
}
