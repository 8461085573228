export default {
	billings: {
		title: 'Bill management',
		bills: 'Bills',
		download_bill: 'Download selected bill',
		shop_name: 'Store name',
		billing_no: 'Number',
		billing_name: 'Name',
		billing_detail: 'Detail',
		price: 'Price',
		pay_method: 'Payment method',
		stauts: 'Status',
		down_success: 'Download success',
		card: 'Monthly virtual card',
		all_pay: 'All payment methods',
		bill_total: 'Total bill amount (USD):',
		download_all: 'Download all bill',
		title_desc:
			'Please refer to the actual deduction amount. The exported data is only accurate to two decimal places and may differ slightly from the actual deduction amount.',
		exclude_zero_bill: 'Exclude zero bills',
	},
}
